body {
  @apply min-h-full w-full;
}
.container13 {
  width: 100%;
  height: auto;
  overflow: hidden;
  gap: 40px;
  background: #0a0c14;
  padding-bottom: 2rem;
  @apply relative flex flex-col items-center justify-center;
}
.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(28, 24, 24, 0.176);
}
.section1 {
  width: 100%;
  height: 100vh;
  position: relative;

  margin: 0 auto;
  @apply relative;
}
.section1 > .img1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: contrast(1.1);
}
.box1 {
  width: 375px;
  height: 377px;
  position: absolute;
  /* background-color: #fff; */
  top: 140px;
  left: 12px;
  z-index: 1;
}
.box1 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.boxheading {
  width: 70%;
  min-height: 300px;
  /* background-color: #443e3e; */
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  gap: 30px;
  transform: translate(-50%, -50%);
  @apply flex flex-col items-center;
}
.boxheading > p {
  width: 80%;
}
.section2 {
  width: 95%;
  min-height: 100vh;
  /* background-color: red; */
  @apply flex flex-col justify-center items-center;
}
.oracleboxheading {
  width: 100%;
  padding: 1rem 0;
  min-height: 100px;
  /* background: #000; */
}
.caardbox {
  width: 100%;
  min-height: 100vh;
  /* background-color: #d68888; */
  @apply relative flex flex-col justify-center items-center;
}
.imgcardboxs {
  width: 100%;
  min-height: 50vh;
  gap: 20px;
  /* background-color: #d68888; */
  @apply relative flex flex-col justify-center items-center;
}
.cardd1 {
  width: 100%;
  height: 100%;
  padding: 2rem 0;
  gap: 20px;
  /* background-color: aquamarine; */
  @apply flex flex-col justify-center items-center;
}
.cardd1 > p {
  width: 65%;
}
.cardd2 > hr {
  width: 100%;
  margin-bottom: 1rem;
  border: 1px solid #0e7490b2;
}
.cardd2 {
  width: 90%;
  height: 100%;
  padding: 2rem 0;
  /* background-color: red; */
  gap: 20px;
  @apply flex flex-col justify-start items-start;
}
.iconbox {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 1px 2px 0.5px #ffffffb2;
  border-bottom: 4px solid #ffffffb2;
  right: 10%;
  @apply absolute top-2/4 translate-x-2/4 translate-y-2/4 flex justify-center items-center;
}
.iconbox2 {
  width: 70px;
  height: 70px;
  border-radius: 50px;
  background-color: #fff;
  box-shadow: 1px 2px 0.5px #ffffffb2;
  border-bottom: 4px solid #ffffffb2;

  left: 5%;
  @apply absolute top-2/4 translate-x-2/4 translate-y-2/4 flex justify-center items-center;
}
.iconbox > svg {
  width: 3rem;
  height: 2.5rem;
}
.iconbox2 > svg {
  width: 3rem;
  height: 2.5rem;
  transform: rotate(180deg);
}

.imgbox {
  width: 100%;
  /* min-height: 350px; */
  padding: 2rem;
  @apply flex justify-center items-center;
}
.imgbox2 {
  width: 100%;
  /* min-height: 350px; */
  /* background-color: #fff; */
  /* padding: 2rem ; */
  @apply flex justify-start items-center;
}
.imgbox > img {
  width: 450px;
  height: 320px;
  border-radius: 20px;
}
.imgbox2 > img {
  width: 174px;
  height: 174px;
  /* border-radius: 20px; */
}
.section3,
.section4 {
  width: 90%;
  min-height: 100vh;
  border-radius: 30px;
  box-shadow: 0px 0px 10px 0px #00000080;
  background: #333333;
  @apply flex flex-col pb-12 overflow-hidden;
}

.imgboxcard {
  width: 100%;
  min-height: 50vh;
  /* background-color: skyblue; */
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.imgboxcard1,
.imgboxcard2 {
  width: 100%;
  height: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  gap: 100px;
  padding: 2rem 0;
}
.cards > .bit {
  width: 185px;
  height: 185px;
}
.cards > .imuu > .padlock4 {
  width: 300px;
  height: 228px;
}
.imgboxcard1 > .cards,
.imgboxcard2 > .cards {
  width: 40%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.imgboxcard1 > .cards > .imuu,
.imgboxcard2 > .cards > .imuu {
  height: 60%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
}
.cards > .imuu > .bitcoin {
  width: 185px;
  height: 185px;
}
.cards > .imuu > .padlock44 {
  width: 239px;
  height: 228px;
}
.cards > .imuu > .padlock4 {
  width: 300px;
  height: 228px;
}

.box:hover {
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
  padding: 0.2rem 0rem;
}
.box {
  /* margin-top: 1.5rem; */
  height: 30%;
  width: 100%;
}
.accordion {
  cursor: pointer;
  padding: 0.5rem 1rem;
  /* margin-top: 1rem; */
  transition: 0.6s ease-in;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  color: #fff;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
  /* margin-top: 1rem; */
  transition: 0.6s ease-in;
  background: #6a6b6e;
  @apply rounded-xl;
}
.name {
  display: flex;
  justify-content: first baseline;
  align-items: center;
}
.name > h2 {
  font-weight: 600;
}

.reveal {
  width: 100%;
  /* min-height: 100px; */
  display: block;
  /* margin-top: 0.5rem; */
  padding: 0.3rem 0rem;
  z-index: 999;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.4rem;
  transition: 0.5s ease;
  letter-spacing: 0.1em;
}
.indicator {
  padding: 0.3rem;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  background-color: #000;
  transition: 0.6s ease;
}
.indicator > svg {
  width: 1.3rem;
  height: auto;
  fill: #fff;
  /* padding : 1rem; */
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
}
.section5 {
  width: 90%;
  min-height: 232px;
  border-radius: 30px;
  box-shadow: 0px 0px 10px 0px #00000080;
  background: #333333;
  padding: 0rem 2.5rem;
  @apply flex flex-col justify-center items-center overflow-hidden;
}
@media screen and (max-width: 280px) {
  .section3,
  .section4,
  .section5 {
    border-radius: 10px;
  }
  .section5 {
    padding: 1rem 1rem;
    align-items: flex-start;
  }

  .section5 > p {
    text-align: left;
  }
  .boxheading {
    width: 100%;
  }
  .boxheading > h1 {
    font-size: 2.3rem;
  }
  .imgboxcard1,
  .imgboxcard2 {
    flex-direction: column;
  }
  .iconbox2 {
    left: -6%;
    top: 75%;
    width: 30px;
    height: 30px;
  }
  .cardd2 > p {
    font-size: 1rem;
  }
  .iconbox > svg,
  .iconbox2 > svg {
    width: 2rem;
    height: 1.5rem;
  }
  .iconbox {
    right: 5%;
    width: 30px;
    height: 30px;
    top: 75%;
  }
  .imgbox > img {
    width: 200px;
    height: 170px;
  }
  .cardd1 > p {
    width: 90%;
  }
  .imgboxcard1 > .cards,
  .imgboxcard2 > .cards {
    width: 100%;
  }
  .oracleboxheading {
    padding: 2rem 0rem;
  }
  .oracleboxheading > h1 {
    font-size: 1.5rem;
  }
  .boxheading > p {
    width: 90%;
    text-align: left;
    font-size: 1rem;
  }
}
@media (min-width: 281px) and (max-width: 365px) {
  .section3,
  .section4,
  .section5 {
    border-radius: 10px;
  }
  .section5 {
    padding: 2rem 1rem;
  }
  .boxheading {
    width: 83%;
  }
  .boxheading > h1 {
    font-size: 2.3rem;
  }
  .imgboxcard1,
  .imgboxcard2 {
    flex-direction: column;
  }
  .iconbox2 {
    left: 0%;
    top: 70%;
    width: 30px;
    height: 30px;
  }
  .cardd2 > p {
    font-size: 1rem;
  }
  .iconbox > svg,
  .iconbox2 > svg {
    width: 2rem;
    height: 1.5rem;
  }
  .iconbox {
    right: 7%;
    width: 30px;
    height: 30px;
    top: 70%;
  }
  .imgbox > img {
    width: 200px;
    height: 170px;
  }
  .cardd1 > p {
    width: 90%;
  }
  .imgboxcard1 > .cards,
  .imgboxcard2 > .cards {
    width: 100%;
  }
  .oracleboxheading {
    padding: 1rem 1rem;
  }
  .boxheading > p {
    width: 90%;
    text-align: left;
  }
}
@media (min-width: 366px) and (max-width: 765px) {
  .imgboxcard1,
  .imgboxcard2 {
    flex-direction: column;
  }
  .imgboxcard {
    min-height: 30vh;
  }
  .section3,
  .section4,
  .section5 {
    border-radius: 10px;
  }
  .section5 {
    padding: 2rem 1rem;
  }
  .section5 > h2 {
    font-size: 1.6rem;
  }
  .iconbox2 {
    left: -3%;
    top: 64%;
    width: 40px;
    height: 40px;
  }
  .caardbox {
    min-height: 60vh;
  }
  .iconbox > svg,
  .iconbox2 > svg {
    width: 2rem;
    height: 1.5rem;
  }
  .iconbox {
    right: 7%;
    width: 40px;
    height: 40px;
    top: 64%;
  }
  .imgbox > img {
    width: 255px;
    height: 241px;
  }
  .cardd1 > p {
    width: 90%;
  }
  .imgboxcard1 > .cards,
  .imgboxcard2 > .cards {
    width: 100%;
  }
  .boxheading {
    width: 100%;
  }
  .boxheading > p {
    width: 90%;
  }
}
@media (min-width: 766px) and (max-width: 912px) {
  .section4,
  .section2,
  .caardbox {
    min-height: 60vh;
  }
  .imgboxcard {
    min-height: 30vh;
  }
  .imgbox > img {
    width: 373px;
    height: 304px;
  }
  .name > h2,
  .section5 > p {
    font-size: 1rem;
  }
  .boxheading {
    width: 100%;
  }
  .imgboxcard2 > .cards > .imuu {
    padding: 1rem 0;
  }
  .imgboxcard1 {
    gap: 50px;
  }
  .imgboxcard1 > .cards {
    width: 43%;
  }
  .imgboxcard2 {
    margin-bottom: 3rem;
  }
  .imgboxcard2 > .cards {
    width: 50%;
  }
}
@media (min-width: 913px) and (max-width: 1024px) {
  .section4,
  .section2,
  .caardbox {
    min-height: 60vh;
  }
  .imgboxcard {
    min-height: 30vh;
  }
  .imgbox > img {
    width: 373px;
    height: 304px;
  }
  .name > h2,
  .section5 > p {
    font-size: 1rem;
  }
  .boxheading {
    width: 100%;
  }
  .imgboxcard2 > .cards > .imuu {
    padding: 1rem 0;
  }
  .imgboxcard1 {
    gap: 50px;
  }
  .imgboxcard1 > .cards {
    width: 43%;
  }
  .imgboxcard2 {
    margin-bottom: 3rem;
  }
  .imgboxcard2 > .cards {
    width: 50%;
  }
}
