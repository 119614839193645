/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  } */
  body {
    @apply min-h-full w-full;
  }
  .container12 {
    width: 100%;
    min-height: 200vh;
    overflow: hidden;
    gap: 40px;
    background: #00352c;
    padding-bottom: 2rem;
    @apply relative flex flex-col items-center justify-center;
  }
  .section1{
    width: 93%;
    border-radius: 50px;
    min-height: 110vh;
    background: #111713;
    overflow: hidden;
    margin-top: 1rem;
    box-shadow: 0px 4px 10px 0px #00000040;
  }

  .walletboxheading {
    width: 100%;
  min-height: 100px;
    display: flex;
    /* background-color: red; */
    padding: 1.5rem 0rem;
    @apply flex-col justify-center items-center ;
  }
  .doo12cardds{
    width: 100%;
    min-height: 100vh;
    /* background-color: red; */
    /* padding: 3rem 2rem; */
     @apply flex items-center justify-between ;
  }
  .door12cards{
    width: 100%;
    min-height: 500px;
    /* background-color: red; */
    padding: 3rem 2rem;
     @apply flex items-center justify-between ;
  }
.door12card{
  width: 30%;
  height: 100%;
  background-color: #fff;
  border-radius: 30px;
  gap: 10px;
  padding: 0 0.3rem;
  overflow: hidden;
  @apply flex flex-col justify-start;
}
.door12nav{
width: 100%;
height: 50px;
/* background-color: red; */
@apply flex items-center justify-between p-7;
}
.door12nav>img{
  width: 30px;
  height: 30px;
}
.cardimgs{
  width: 100%;
  height: 250px;
  /* background-color: red; */
  @apply flex justify-center items-center;
}
.cardimgs>img{
 width: 400px;
 height: 250px;
}
.cardimgs>img:hover{
  /* padding: 0.1rem 0; */
  transform: scale(1.2);
transition: 0.6s ease;
}
.doo12footer{
  width: 80px;
  height: 80px;
  /* background-color:#E6E6E6;/ */
  box-shadow: 10px 3px 13px   #E6E6E6;
  filter: drop-shadow(10px 10px 10px  #32313109);
  color: #696969;
  background-color: #fff;
  border-radius: 20px;
  @apply flex justify-center items-center;
}
.doo12footer>span{
  width: 30px;
  fill: grey;
  height: 30px;
 
}
  .section22,.section221{
    width: 93%;
    border-radius: 50px;
    min-height: 100vh;
    background: #111713;
    /* background-color: #ad4a4a; */
    overflow: hidden;
    padding: 2rem 1.5rem;
    box-shadow: 0px 4px 10px 0px #00000040;
    @apply flex flex-col  items-center;
  }
 .section221{
  background-color: #09090b;
 }
  .section22>hr{
    /* padding: 2rem; */
    width: 90%;
   
  }
  .cardboxsss>div,.cardboxsss1>div{
    min-height: 40vh;
  }
  .cardboxsss,.cardboxsss1{
    width: 100%;
    min-height: 100vh;
    padding: 3rem 2rem;
    /* background-color: red; */
    @apply flex flex-wrap items-center justify-center;
  }
  .cards{
    padding: 1rem 1.5rem;
    width: 40%;
    min-height: 320px;
    /* min-height: 250px; */
    /* margin-left: 1rem; */
     background: #E6E6E6;
     box-shadow: 0px 0px 10px 0px #0000004D;
    border-radius: 30px;
    gap: 16px;
    @apply flex flex-col items-center justify-center;
  }
  .cardboxsss>:nth-child(1),.cardboxsss>:nth-child(1){
    z-index: 9;
  }
  .cardboxsss>:nth-child(2){
    z-index: 1;
    margin-top: -5rem;

  }
  .cardboxsss1>:nth-child(2),.cardboxsss>:nth-child(2){
    z-index: 9;
    margin-top: -5rem;

  }
  .cardboxsss1>:nth-child(1){
    z-index: 1;

  }
 .cards>p{
    width: 60%;
    line-height: 30px;
  }
  .cardboxsss>div>.cards>p{
    width: 60%;
    line-height: 30px;
  }
   .cardboxsss1>div>.cards>p{
    width: 90%;
    line-height: 30px;
   }
   .cardboxsss1>.cards>p{
    width: 70%;
    line-height: 30px;
   }
  .section21{
    width: 93%;
    border-radius: 50px;
    min-height: 100vh;
  }
  .oracleboxheading{
    padding: 1rem 0rem;
  }
  .doo11cardds{
    width: 100%;
    min-height: 100vh;

    /* margin-top: 1.5rem; */
    @apply flex justify-between items-center;
  }
.door11cards{
    width: 100%;
    min-height: 550px;
    margin-top: 1.5rem;
    @apply flex justify-between items-center;
  }
  .door11card1{
 width: 30%;
height: 100%;
padding-top: 1rem;
padding-bottom: 2rem;
transition: 0.6s ease;
/* background-color: red; */
gap: 10px;
@apply flex items-center justify-center flex-col;
  }
  .door11card1:hover{
    padding: 0.1rem 0;
transition: 0.6s ease;
  }
  .door11card1>h1{
    text-align: center;
  }
  .cardimg1{
    width: 100%;
    height: 70%;
    padding: 1rem 0;
@apply flex items-center justify-center;
  }
  .cardimg1>img{
    width: 250px;
    height: 250px;
    filter:drop-shadow(7px 7px 10px #0000004D);
  }
  .cardimg1>img:hover{
    padding: 0.1rem 0;
transition: 0.6s ease;
  }
  .cardtextbox{
    width: 100%;
    min-height: 25%;
    gap: 10px;
    @apply flex flex-col items-center justify-center;
  }
  .cardtextbox>p{
    width: 85%;
font-weight: 400;
font-size: 18px;
  }
  .section2{
    width: 93%;
    min-height: 380px;
    padding: 1.5rem 0;
    @apply flex flex-col items-start justify-center overflow-hidden;
  }
  .webimg {
    width: 100%;
    height: 150px;
    @apply flex items-center justify-center;

  }
  .webimg>img{
    height: 150px;
    width: 150px;
  }
  .webimg>img:hover{
    margin-top: 0.6rem ;
transition: 0.6s ease-in;
  }
  .paragraph{
    width: 100%;
    padding: 1rem;
    @apply flex items-center justify-center;
  }
  .paragraph > p{
    width: 85%;
font-weight: 400;
line-height: 42px;
letter-spacing: 2px;
text-align: center;
transition: 0.6s ease;

  }
  
.section3 {
    width: 93%;
    border-radius: 50px;
    background-color: #111713;
    min-height: 300px;
    padding: 1.5rem 2.5rem;
    gap:10px;
    @apply flex items-start flex-col justify-start overflow-hidden;
  }
  .doo11sec3box{
    display: flex;
    width: 100%;
    height: 300px;
  }
  .doo11sec3box{
width: 100%;
height: 100%;
gap: 16px;
    padding: 1rem 0;
    @apply flex flex-col;

  }
  .btn:hover{
    transition: 0.6s ease;
  }
  .btn{
    width: 186px;
height: 43px;
padding: 18px 32px 18px 32px;
border-radius: 30px;
text-decoration: underline;
background: #F3CC2FCC;
color: #ffff;
font-weight: 600;
box-shadow: 0px 4px 15px 0px #2E2E2E26;
@apply flex items-center justify-center;
  }
@media screen and (max-width:280px) {
  .section1,.section22,.section3,.section221{
    border-radius: 10px;
    width: 93%;
  }
  .cards>p,.cardboxsss>div>.cards>p{
    width: 87%;
  }
  .cards>h1{
    font-size: 1rem;
  }
  .cards{
    width: 100%;
    border-radius: 10px;
    padding: 2rem 0.1rem;
  }
  .cardboxsss>div,.cardboxsss1>div{
    flex-direction: column;
    gap: 20px;
    min-height: 35vh;

  }
  .cardboxsss>:nth-child(2) ,.cardboxsss1>:nth-child(2)  {
    z-index: 9;
    margin-top: 0rem;
  }
  
  .cardboxsss,.cardboxsss1{
    flex-direction: column;
    padding: 2rem 0rem;
    gap: 20px;
  }
  .cardtextbox>p{
    width: 95%;
  }
  .section3{
    padding: 1rem;
  }
  .walletboxheading>h1{
    font-size: 1.8rem;
  }
  .doo12cardds{
    flex-direction: column;   
  }
  .door12cards{
    flex-direction: column;
    gap: 30px;
    padding: 2rem 1rem;
  }
  .door12card,.door11card1{
border-radius: 10px;
    width: 100%;
    padding: 1rem 0rem;
  }
  .door12nav{
    padding: 0.8rem 0.4rem;
  }
  .doo12footer{
    width: 50px;
    height: 50px;
  }
  .oracleboxheading>h1{
    font-size: 2rem;
  }
  .door11cards{

    flex-direction: column;
  }
  .cardimg1>img{
    width: 200px;
    height: 200px;
  }
  .cardboxsss1>.cards>p{
    /* background-color: #00352c; */
    width: 90%;
  }
  .cardboxsss1>.cards>h1{
    text-align: center;
  }
  .paragraph>p{
    width: 100%;
    letter-spacing: 0px;
    line-height: 30px;
    font-size: 1rem;
  }
}  

@media (min-width:281px) and (max-width:765px) {
  .section1,.section22,.section3,.section221{
    border-radius: 10px;
    width: 93%;
  }
  .cards>p,.cardboxsss>div>.cards>p{
    width: 87%;
  }
  .cards>h1{
    font-size: 1.25rem;
  }
  .cards{
    width: 100%;
    border-radius: 10px;
    padding: 2rem 0.1rem;
  }
  .cardboxsss>div,.cardboxsss1>div{
    flex-direction: column;
    gap: 20px;
min-height: 45vh;
  }
  .cardboxsss>:nth-child(2) ,.cardboxsss1>:nth-child(2)  {
    z-index: 9;
    margin-top: 0rem;
  }
  
  .cardboxsss,.cardboxsss1{
    flex-direction: column;
    padding: 2rem 0rem;
    gap: 20px;
  }
  .cardtextbox>p{
    width: 95%;
  }
  .section3{
    padding: 1rem;
  }
  .walletboxheading>h1{
    font-size: 1.8rem;
  }
  .doo12cardds{
    flex-direction: column;   
  }
  .door12cards{
    flex-direction: column;
    gap: 30px;
    padding: 2rem 1rem;
  }
  .door12card,.door11card1{
border-radius: 10px;
    width: 100%;
    padding: 1rem 0rem;
  }
  .door12nav{
    padding: 0.8rem 0.4rem;
  }
  .doo12footer{
    width: 50px;
    height: 50px;
  }
  .oracleboxheading>h1{
    font-size: 2rem;
  }
  .door11cards{

    flex-direction: column;
  }
  .cardimg1>img{
    width: 200px;
    height: 200px;
  }
  .cardboxsss1>.cards>p{
    /* background-color: #00352c; */
    width: 90%;
  }
  .cardboxsss1>.cards>h1{
    text-align: center;
  }
  .paragraph>p{
    width: 100%;
    letter-spacing: 1px;
    line-height: 40px;
    font-size: 1.3rem;
  }
} 
@media (min-width:766px) and (max-width:911px) {
  .section1,.section21,.section3,.section22,.section221{
    min-height: 50vh;
width: 95%;
border-radius: 15px;
  }
  .walletboxheading>h1{
    font-size: 2rem;
  }
  .cardboxsss1>.cards>p{
    width: 100%;
    line-height: inherit;
  }
  .cards>h1{
    text-align: center;
  }
  .cardboxsss1>div>.cards>p {
    width: 100%;
    line-height: inherit;
}
  .cardboxsss>div>.cards>p{
    width: 100%;
    font-size: 1rem;
  }
  .cards{
    border-radius: 15px;
  }
  .cards>p{
    width: 100%;
  }
  .doo12cardds{
    min-height: 40vh;
  }
  .doo11cardds{
    min-height: 40vh;
  }
.door12cards{
  padding: 1rem 0.8rem;

}
.cardboxsss1,.cardboxsss{
  min-height: 46vh;
    padding: 2rem 0.6rem;
}
.cardboxsss>div,.cardboxsss1>div{
  /* min-height: 54vh; */
  min-height: 30vh;
}
.cardboxsss1>:nth-child(2),.cardboxsss>:nth-child(2) {
  margin-top: -1rem;
}
.door12card{
  border-radius: 10px;
  width: 31.5%;
}
.door12nav{
  padding: 0.3rem;
}
.door12nav>span{
  font-size: 1rem;
  line-height: 1rem;
}
.doo12footer{
  width: 50px;
  height: 50px;
  border-radius: 10px;
}
.oracleboxheading>h1{
  font-size: 2.5rem;
}
.paragraph>p{
  width: 100%;
}
}
@media (min-width:912px) and (max-width:1024px) {
  .section1,.section21,.section3,.section22,.section221{
    min-height: 50vh;
width: 95%;
border-radius: 15px;
  }
  .cardboxsss1>.cards>p{
    width: 100%;
  }
  .cards>h1{
    text-align: center;
  }
  .cardboxsss>div>.cards>p{
    width: 100%;
    font-size: 1rem;
  }
  .cards{
    border-radius: 15px;
    width: 35%;
  }
  .doo12cardds{
    min-height: 40vh;
  }
  .doo11cardds{
    min-height: 40vh;
  }
.door12cards{
  padding: 1rem 0.8rem;

}
.cardboxsss1,.cardboxsss{
  min-height: 46vh;
    padding: 2rem 2rem;
}
.cardboxsss>div,.cardboxsss1>div{
  /* min-height: 54vh; */
  min-height: 25vh;
}
.door12card{
  border-radius: 10px;
  width: 31.5%;
}
.door12nav{
  padding: 0.3rem;
}
.door12nav>span{
  font-size: 1rem;
  line-height: 1rem;
}
.doo12footer{
  width: 50px;
  height: 50px;
  border-radius: 10px;
}
.oracleboxheading>h1{
  font-size: 2.5rem;
}
.paragraph>p{
  width: 100%;
}
}
