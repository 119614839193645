/* @import url('https://fonts.googleapis.com/css2?family=Raleway'); */
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Fredoka:wght@300..700&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');


body {
  /* font-family: Gilroy; */
  font-family: 'Nunito', sans-serif;
  letter-spacing: 0.1em;
  @apply min-h-full w-full;
}

.container {
  display: flex;
  width: 100%;
  height: auto;
  background-color: #075985;
  overflow: hidden;
  gap: 40px;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
  @apply relative flex flex-col;
}
.card1 {
  width: 90%;
  min-height: 370px;
  background-color: #082f49;
  margin-top: 2rem;
  border-radius: 50px;
  overflow: hidden;
  display: flex;
  box-shadow: 0px 0px 10px 0px #00000066;
  justify-content: space-between;
  @apply justify-between  overflow-hidden;
}
.walletboxheading {
  width: 65%;
  height: 100%;
  display: flex;
  margin-left: 1rem;
  @apply flex-col justify-start items-start p-12;
}
.walletimgbox {
  width: 35%;
  height: 100%;
  /* background-color: #d67373; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.walletimgbox > img {
  width: 400px;
  height: 400px;
  transition: all ease 2s;
filter: drop-shadow(10px 10px 7px  #00000066);
  margin-left: -3rem;
}
.walletboxheading > p {
  letter-spacing: 0.1em;
}

.card2 {
  width: 90%;
  min-height: 600px;
  background-color: #082f49;
  border-radius: 50px;
  box-shadow: 0px 0px 10px 0px #00000066;
  @apply pb-12 overflow-hidden;
}
.walletboxheading2 {
  width: 100%;
  padding-top: 1rem;
  height: 100px;
  @apply flex justify-center items-center;
}
.walletboxheading2 > h1 {
  width: 63.5%;
  text-align: center;
}

.imgboxcard {
  width: 100%;
min-height:50vh;
  /* background-color: rgb(53, 88, 103); */

  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 3rem;
  margin-bottom: 1rem;
}

.imgboxcard1,.imgboxcard2{
width: 100%;
height: 100%;
display: flex;

}
.imgboxcard1{
  padding-top: 3rem;
  padding-bottom: 5rem;
gap: 20px;
/* background-color: red; */

}
.imgboxcard2{
  justify-content: center;
 padding-bottom: 5rem;
 gap: 50px;

}
.imgboxcard1 > .cards,
.imgboxcard2 > .cards {
  width: 33%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.imgboxcard1 > .cards > .imuu,
.imgboxcard2 > .cards > .imuu
 {
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: red; */
  padding: 1rem 0;
}

.box {
  height: 30%;
  width: 100%;
  
  display: flex;
  flex-flow: column;
  align-items: center;
}
.cards>.imuu>img{
filter: drop-shadow(7px 10px 7px  #00000066);
}
.cards > .imuu > .mobile {
  height: 187px;
  width: 87px;
}
.cards > .imuu > .hardware{
  width: 160px;
  height: 188px;
}
.imgboxcard1 > .cards > .imuu > .paper {
  width: 138px;
  height: 155px;
}

.cards > .imuu > .brain {
  width: 138px;
  height: 138px;
}
.cards > .imuu > .manage {
  width: 150px;
  height: 150px;
}

.accordion {
  cursor: pointer;
  /* background-color: #df5151; */
  transition: 0.6s ease-in;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  /* min-width: 200px; */
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  /* background-color: teal; */

}
.cards:hover {
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
  /* background-color: #082f49; */
  padding: 0.1rem 0rem;
}
.btn:hover {
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 1s;
  background-color: #9a851c;
  transform: scale(1.04);
}
.indicator {
  display: flex;
  justify-content: center;
  align-items: center;
}
.name {
  width: 200px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* background-color: rgb(54, 22, 22); */

  /* @apply flex items-center; */
}
.name > h2 {
  font-weight: 600;
  /* letter-spacing: 1px; */
  font-size: 1.3rem;

}
.reveal {
  display: flex;
  /* margin-top: 0.5rem; */
  padding: 0.3rem 1rem;
  z-index: 999;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.4rem;
  transition: 0.5s;
  /* letter-spacing: 1px; */
  align-items: center;
  justify-content:  center;
  /* background-color: red; */
}
.indicator {
  padding: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #075985;
  @apply rounded-full;
}
.indicator > svg {
  width: 1rem;
  height: auto;
  fill: #fff;
  /* padding : 1rem; */
}
.card3 {
  width: 90%;
  min-height: 350px;
  background-color: #082f49;
  border-radius: 50px;
  overflow: hidden;
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  box-shadow: 0px 0px 10px 0px #00000066;
  flex-direction: column;
}
.btn {
  /* padding: 0.8rem 1.7rem; */
  padding: 0.8rem 1.7rem;
  width: 25%;
  background: 80% #f3cc2fcc;
  border-radius: 30px;
  box-shadow: 0px 4px 15px 0px #2e2e2e26;
  color: aliceblue;
  cursor: pointer;
filter: drop-shadow(7px 10px 7px  #00000066);
  /* margin-top: 1rem; */
}

.walletboxheading3 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem 3rem;
  gap:1rem;
  margin-left: 1rem;
  flex-direction: column;
}
@media screen and (max-width: 280px) {
  /* .walletboxheading {
    padding-left: 1rem;
    @apply flex-col justify-start items-start pt-3;
  } */
  .card1 {
    flex-direction: column;
  }
  .walletimgbox {
    width: 100%;
  }

  .name > h2 {
    font-size: 0.9rem;
  }
  .walletboxheading {
    padding-left: 1.2rem;
    width: 100%;
    @apply flex-col justify-start items-start pt-5;
  }
  .name {
    /* padding: 0 1rem; */
  }
  .title {
    min-width: 100px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-self: center;
    gap: 10px;
  }
  .name > :nth-child(1) {
    padding: 0 2rem;
  }
  .imgboxcard1 > .cards,
  .imgboxcard2 > .cards {
    /* width: 280px; */
    width: 100%;
  }
  .imgboxcard1,
  .imgboxcard2 {
    flex-direction: column;
  }
  
  .btn {
    /* padding: 0.4rem 1rem; */
    width: 100%;
    margin-bottom: 1rem;
  }
  .walletboxheading2 {
    padding-top: 1.2rem;
  }
  .walletboxheading2 > h1 {
    font-size: 1.3rem;
    line-height: 2rem;
    width: 80.5%;
  }
  .walletboxheading3 {
    /* padding-left: 1rem; */
    padding: 2rem 0;
    width: 85%;

    @apply flex-col justify-start items-start pt-3;
  }

  .walletboxheading3 > p {
    /* font-size: 1rem; */
  }

  .walletimgbox > img {
    width: 250px;
    height: 250px;
    margin-left: 0rem;
  }
  .card1,
  .card2,
  .card3 {
    @apply rounded-lg;
  }
}
@media (min-width: 289px) and (max-width: 765px) {
  .card1 {
    flex-direction: column;
  }
  .name > h2 {
    font-size: 1.2rem;
  }
  .cards {
   
    min-width: 100%;
  }
  .walletimgbox {
    width: 100%;
  }
  .imgboxcard1,
  .imgboxcard2 {
    flex-direction: column;
  }
  .walletimgbox > img {
    margin-left: 0;
  }
  .walletboxheading {
    width: 100%;
  }
  .walletboxheading3 {
    /* padding-left: 1rem; */
    padding: 2rem 0;
    width: 85%;
    @apply flex-col justify-start items-start pt-3;
  }
  .walletboxheading {
    padding-left: 1.2rem;
    @apply flex-col justify-start items-start pt-5;
  }
  .walletboxheading2 {
    padding: 5rem 0;
  }
  .walletboxheading2 > h1 {
    width: 80%;
    font-size: 1.3rem;
  }
  .card1,
  .card2,
  .card3 {
    border-radius: 20px;
    width: 95%;
  }
  .btn {
    /* padding: 0.4rem 1rem; */
    width: 55%;
    margin-bottom: 1rem;
  }
}
@media (min-width: 766px) and (max-width: 912px) {
  .card1 {
    flex-direction: column;
  }
  .btn {
    /* padding: 0.4rem 1rem; */
    
    margin-bottom: 1rem;
  }
  .imgboxcard1 > .cards {
    width: 30%;
  }
  .imgboxcard2 > .cards {
    width: 40%;
  }
  .walletimgbox {
    width: 100%;
  }
 
  
.title{
  gap: 0;
}
  .walletimgbox > img {
    margin-left: 0;
  }
  .walletboxheading {
    width: 100%;
  }
  .walletboxheading3 {
    /* padding-left: 1rem; */
    padding: 2rem 0;
    width: 85%;
    @apply flex-col justify-start items-start pt-3;
  }
  .walletboxheading {
    padding-left: 1.2rem;
    @apply flex-col justify-start items-start pt-5;
  }
  .walletboxheading2 {
    padding: 5rem 0;
  }
  .walletboxheading2 > h1 {
    width: 90%;
    font-size: 2rem;
  }
  .card1,
  .card2,
  .card3 {
    border-radius: 20px;
    width: 95%;

  }
  .reveal{
    font-size: 1rem;
  }
  .imgboxcard{
    min-height: 35vh;
  }
}

@media (min-width: 912px) and (max-width: 1024px) {
  .card1 {
    flex-direction: column;
  }
 
  .walletimgbox {
    width: 100%;
  }
 
  .walletimgbox > img {
    margin-left: 0;
  }
  .walletboxheading {
    width: 100%;
  }
  .walletboxheading3 {
    /* padding-left: 1rem; */
    padding: 2rem 0;
    width: 85%;
    @apply flex-col justify-start items-start pt-3;
  }
  .walletboxheading {
    padding-left: 1.2rem;
    @apply flex-col justify-start items-start pt-5;
  }
  .walletboxheading2 {
    padding: 5rem 0;
  }
  .walletboxheading2 > h1 {
    width: 90%;
    font-size: 2rem;
  }
  .card1,
  .card2,
  .card3 {
    border-radius: 20px;
  }
  .imgboxcard{
    min-height: 30vh;
  }
}
