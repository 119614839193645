/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */
body {
  @apply min-h-full w-full;
}
.container10 {
  width: 100%;
  height: auto;
  overflow: hidden;
  gap: 40px;
  background: #a3a3a3;
  padding-bottom: 2rem;
  @apply relative flex flex-col items-center justify-center;
}
.section1 {
  min-height: 270px;
  width: 90%;
  background-color: #a3a3a3;
  margin-top: 1rem;
  @apply flex justify-between flex-col overflow-hidden;
}
.tokenheading {
  @apply flex flex-col items-center justify-start p-12;
}

.tokenheading > h1 {
  letter-spacing: 1px;
}

.bitcoinimgbox {
  width: 100%;
  min-height: 320px;
  background-color: #a3a3a3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bitcoinimgbox > img {
  width: 419px;
  height: 419px;
  /* filter: contrast(1); */
  transition: all ease 2s;
}
.section2 {
  width: 90%;
  min-height: 100vh;
  border-radius: 30px;
  box-shadow: 0px 0px 10px 0px #00000080;
  background: #d9d9d9;
  @apply flex flex-col pb-12 overflow-hidden;
}
.cards,
.cardsss {
  width: 100%;
  min-height: 200px;
  padding: 3rem 3rem;
  @apply flex justify-between items-center;
}
.text {
  width: 45%;
  min-height: 400px;
  padding: 2rem 1.5rem;
  background-color: #a3a3a3;

  @apply flex flex-col justify-start items-start;
}

.text > h1 {
  text-align: left;
}
.text p {
  width: 90%;
  white-space: pre-line;
  line-break: normal;
  /* transition: all cubic-bezier(0.19, 1, 0.22, 1) 1s; */
  transition: 0.7s ease;
}
.cardimgs {
  width: 55%;
  height: 100%;
  @apply flex justify-center items-center;
}
.cardimgs > img {
  width: 400px;
  height: 400px;
}
.section3 {
  width: 90%;
  border-radius: 30px;
  background-color: #d9d9d9;
  min-height: 200px;
  padding: 1rem 3rem;
  @apply flex items-center justify-center overflow-hidden;
}
.section3 > p {
  line-height: 2rem;
}
.section4 {
  width: 90%;
  border-radius: 30px;
  background-color: #d9d9d9;
  min-height: 200px;
  padding: 1rem 3rem;
  @apply flex items-center justify-between overflow-hidden;
}
.tokenbox {
  width: 70%;
  min-height: 200px;
  @apply flex flex-col flex-wrap items-start justify-start;
}
.btnbox {
  width: 100%;
  @apply flex mt-3 justify-start gap-20;
}
.btn {
  width: 186px;
  height: 43px;
  background: #f3cc2f;
  border-radius: 30px;
  color: #fff;
  font-weight: 600;
  text-decoration: underline;
  box-shadow: 0px 4px 15px 0px #2e2e2e26;
}
.bgimgbox {
  width: 30%;
  height: 100%;
  @apply flex items-center justify-center;
}
.bgimgbox > img {
  height: 214px;
  width: 214px;
}
@media screen and (max-width: 280px) {
  .section1,
  .section2,
  .section3,
  .section4 {
    width: 94%;
    border-radius: 10px;
  }
  .tokenheading {
    padding: 0.3rem 1rem;
    align-items: flex-start;
  }
  .tokenheading > h1 {
    text-align: left;
    font-size: 2rem;
  }
  .tokenheading > p {
    text-align: left;
  }
  .cards,
  .cardsss {
    flex-direction: column;
    padding: 1rem 0.3rem;
  }
  .cardsss {
    flex-direction: column-reverse;
  }
  .text {
    width: 100%;
    padding: 0.5rem;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .cardimgs,
  .cardimgss {
    width: 100%;
  }
  .cardimgs > img {
    width: 250px;
    height: 250px;
  }
  .text > h1 {
    font-size: 1.2rem;
  }
  .section3,
  .section4 {
    padding: 1rem;
  }
  .section4 {
    @apply flex-col;
  }
  .tokenbox {
    @apply flex-col;
  }
  .btnbox {
    gap: 10px;
  }
  .tokenbox,
  .bgimgbox {
    width: 100%;
  }
  .bgimgbox {
    padding-top: 1rem;
  }
}
@media (min-width: 289px) and (max-width: 765px) {
  .section1,
  .section2,
  .section3,
  .section4 {
    width: 94%;
    border-radius: 10px;
  }
  .btnbox {
    gap: 10px;
  }
  .section4 {
    @apply flex-col;
  }
  .tokenbox,
  .bgimgbox {
    width: 100%;
  }
  .tokenheading {
    padding: 0.3rem 1rem;
    align-items: flex-start;
  }
  .tokenheading > h1 {
    text-align: left;
  }
  .tokenheading > p {
    text-align: left;
  }
  .cards,
  .cardsss {
    flex-direction: column;
    padding: 1rem 0.5rem;
    /* padding-left: 1rem; */
    gap: 20px;
  }

  .cardsss {
    flex-direction: column-reverse;
  }
  .text {
    width: 100%;
    padding: 1.3rem;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .cardimgs,
  .cardimgss {
    width: 100%;
  }
  .cardimgs > img {
    width: 250px;
    height: 250px;
  }

  .section3,
  .section4 {
    padding: 1rem 1.5rem;
  }
}
@media (min-width: 767px) and (max-width: 912px) {
  .section1,
  .section2,
  .section3,
  .section4 {
    width: 96%;
    border-radius: 10px;
  }
  .section3,
  .section4 {
    padding: 1rem 1.5rem;
  }
  .tokenheading {
    padding: 0.3rem 1rem;
    align-items: flex-start;
  }
  .tokenheading > h1 {
    text-align: left;
  }
  .tokenheading > p {
    text-align: left;
  }
  .cards,
  .cardsss {
    /* flex-direction: column; */
    padding: 1rem 0.8rem;
    padding-left: 1rem;
    gap: 20px;
  }

  .cardsss {
    /* flex-direction: column-reverse; */
  }
  .text {
    width: 100%;
    padding: 1.3rem;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .cardimgs,
  .cardimgss {
    width: 100%;
  }
  .cardimgs > img {
    width: 250px;
    height: 250px;
  }
}
