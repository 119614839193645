/* Animation property */
.giftbox {
    animation: wiggle 2s linear infinite;
    /* color: #607989; */
  }
  
  /* Keyframes */
  @keyframes wiggle {
    0%, 7% {
      transform: rotateZ(0);
    }
    15% {
      transform: rotateZ(-15deg);
    }
    20% {
      transform: rotateZ(10deg);
    }
    25% {
      transform: rotateZ(-10deg);
    }
    30% {
      transform: rotateZ(6deg);
    }
    35% {
      transform: rotateZ(-4deg);
    }
    40%, 100% {
      transform: rotateZ(0);
    }
  }
  
 
  
  /* .giftbox {
 
    left: calc(50% - 3em);
    top: calc(50% - 2em);
    transform-origin: 50% 5em;
  } */