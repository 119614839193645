@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Montserrat:wght@600;700;900&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,600;0,700;1,500;1,600;1,700&family=Roboto+Slab:wght@500;600;700&display=swap"); */
/* @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,500;1,400&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Comfortaa'); */

.animate-charcter {
  /* font-family: 'Dancing Script', cursive;
  font-weight: bold; */
  /* font-family: 'Montserrat', sans-serif; */
}

/* 
.gradient_background {
    background: linear-gradient(90deg, #F14658, #FF6B00);
    height: 100vh;
    width: 100vw;
  } */

/* .subheading{
  font-family: 'Open Sans', sans-serif;
font-family: 'Roboto Slab', serif;
} */

/* Animate Charectrer */

/* .animate-character
{
  
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #fff800 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: text_clip 10s linear infinite;
  display: inline-block;


  font-family: 'Dancing Script', cursive;
  font-weight: bold;


}

@keyframes text_clip {
  to {
    background-position: 200% center;
  }
} */

/* for image smooth trasition in background  */

/* Hero.css */
.crossfade-transition {
  transition: opacity 1s ease-in-out;
}

.crossfade-hidden {
  opacity: 0;
}
