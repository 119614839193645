@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@600;700&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Comfortaa'); */
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Fredoka:wght@300..700&family=Nunito:ital');


@font-face {
    font-family: "Voyage";
    src: url(../../Fonts/Voyage-Regular.ttf);
  }
@font-face {
    font-family: "Voyage Bold";
    src: url(../../Fonts/Voyage-Bold.ttf);
  }

.Logo_heading{
    /* font-family: 'Oswald', sans-serif; */
    font-family: "Nunito",sans-serif ;
letter-spacing: 2px;

}

.navbar.active {
    /* background: linear-gradient(90deg, rgb(66, 2, 194) 0%, rgb(0, 78, 194) 100%); */
background-color:  #082f49;
/* color: #668294; */
/* color: #dff6e0; */
/* color: #3c8452; */
/* 86a1b3, bg- d4dde4 btn - dff6e0 , 3c6784*/
/* mix-blend-mode: difference; */


}