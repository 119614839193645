body {
  @apply min-h-full w-full;
}
.container4 {
  width: 100%;
  height: auto;
  overflow: hidden;
  gap: 40px;
  background-color: #3f6212;
  padding-bottom: 2rem;
  @apply relative flex flex-col items-center justify-center;
}
.section1 {
  background:linear-gradient(to right, #4d7c0f 70%, #3f6212  );
  width: 90%;
  min-height: 370px;
  margin-top: 2rem;
  box-shadow: 0px 0px 10px 0px #00000080;
  border-radius: 50px;
  z-index: 1;
  position: relative;
  @apply flex justify-between  overflow-hidden;
}
.walletboxheading {
  width: 70%;
  min-height: 100%;
  display: flex;
  /* background-color: red; */
  @apply flex-col justify-start items-start pl-12 pt-2;
}
.walletimg{
width: 30%;
min-height:100%;
z-index: 99999;
padding-left: 2rem;
  @apply flex justify-center items-center ;
}
.walletimg>img{

filter:drop-shadow(7px 7px 10px 0px #00000066);

}
.section2 {
  background: #4d7c0f;
  width: 90%;
  min-height: 100vh;
  border-radius: 50px;
  box-shadow: 0px 0px 10px 0px #00000080;

  @apply pb-12 overflow-hidden;
}
.nftboxheading2 {
  width: 100%;
  /* padding: 3rem 2rem; */
  min-height: 100px;
  @apply flex justify-center items-center pl-12 pt-2;
}
.nftboxheading21{
  width: 100%;
padding-top: 3rem;
padding-left: 3rem;
  min-height: 100px;
  @apply flex justify-start items-start pl-14 ;
}
.nftboxheading21>p{
width: 90%;
}

.nftboxheading2 > h1{
text-align: left;
}

/*second section  */
.imgboxcard {
  width: 100%;
min-height: 50%;
  /* background-color: skyblue; */
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* margin-bottom: 1.5rem; */
}

.imgboxcard1{
width: 100%;
height: 100%;
display: flex;
/* align-items: center; */
justify-content: center;
gap: 100px;
padding: 2rem 0;
}


.imgboxcard1> .cards {
  width: 43%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.imgboxcard1 > .cards > .imuu
 {
  width: 100%;
  height: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
}

.box {
  height: 30%;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.cards>.imuu>.padlock{
  width:185px;
  height: 185px;
}
.cards>.imuu>.cryptowallet{
  height: 185px;
  width: 200px;
}
.accordion {
  cursor: pointer;
  height: 25%;
  /* padding: 1rem 1rem; */
  /* margin-top: 1rem; */
  transition: 0.6s ease-in;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  /* min-width: 200px; */
  color: #fff;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
  /* margin-top: 1rem; */
  transition: 0.6s ease-in; 
  background: #A3E63580;

  @apply rounded-xl;

}
.cards>.box:hover {
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
  /* background-color: #505B6B; */
  padding: 0.1rem 0rem;
}

.indicator {
  display: flex;
  justify-content: center;
  align-items: center;
}
.name {
  /* width: 350px; */
  display: flex;
  justify-content: first baseline;
  align-items: center;

  /* @apply flex items-center; */
}
.name > h2 {
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 1px;
}

.reveal {
  width: 100%;
  /* min-height: 100px; */
  display: block;
  /* margin-top: 0.5rem; */
  padding: 0.3rem 0rem;
  z-index: 999;
  color: #fefffc;
  font-size: 1.1rem;
  font-weight: light;
  line-height: 1.4rem;
  transition: 0.5s ease;
  letter-spacing: 0.1em;
  /* background-color: red; */
}
.indicator {
  padding: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3f6212;
  @apply rounded-full;
}
.indicator > svg {
  width: 1rem;
  height: auto;
  fill: #fff;
  /* padding : 1rem; */
}
.section3 {
  width: 90%;
  min-height: 350px;
  background-color: #4d7c0f;
  border-radius: 50px;
  overflow: hidden;
  padding-bottom: 1rem;
  display: flex;
  padding: 0 2rem;
  padding-bottom: 2rem;
  box-shadow: 0px 0px 10px 0px #00000066;
  flex-direction: column;
}
.nftboxheading3 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.7rem 0rem;
  flex-direction: column;
}
.nfth1 {
  width: 100%;
  height: 20%;
  display: flex;
  align-items: flex-start;
  padding: 1rem 1rem;
  justify-content: flex-start;
}

.nfttextbox{
width: 100%;
min-height: 200px;
padding: 0rem 1rem;
@apply flex justify-start pb-4 ;
}
.nfttextbox>.div2>p{
  width: 80%;
}
.div1{
width: 60%;
}
.div2{
  width: 40%;

    }
.btn {
  padding: 0.8rem 1.7rem;
  height: 43px;
  width: 186px;
  box-shadow: 0px 4px 15px 0px #2e2e2e26;
  background: #F3CC2FCC;
  border-radius: 30px;
  color: aliceblue;
  /* text-decoration: underline; */
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 1rem;
filter: drop-shadow(7px 10px 7px  #00000066);
   @apply flex items-center justify-center;
}

@media screen and (max-width: 280px) {
  .section1,
  .section2,.section3 {
    border-radius: 10px;
    padding: 0.4rem;
    @apply flex-col gap-10;
  }
  .nftboxheading2 {
      padding: 0.5rem 0rem;
    }
    .nfttextbox{
      padding: 0 0.9rem;
      @apply flex-col;
    }
    .nfth1>h1{
      font-size: 1.6rem;
      line-height: 1.9rem;
      text-align: left;
    }
    .div1,.div2{
      width: 100%;
        }
        .div1>p{
          font-size: 1rem;
        }
        .nfttextbox>.div2>p{
          width: 100%;
          font-size: 1rem;
        }
  .nftboxheading2 > h1 {
      width: 100%;
      font-size: 0.94rem;
      line-height: 1.5rem;
    }
  .walletboxheading {
    width: 100%;
    @apply pl-4;
  }
  .walletimg{
      width: 100%;
  } 
  .imgboxcard {
    min-height: 30vh;
  }
  .imgboxcard1{
    flex-direction: column;
  }
 
  .imgboxcard1 > .cards {
    width: 100%;
    height: 40%;
  }

  .box {
    width: 100%;
    height: 40%;
    /* justify-content: flex-start; */
  }
  .nftboxheading21 > p {
    width: 100%;
    font-size: 0.9rem;
    text-align: left;
    line-height: 1.5rem;
  }
  .nftboxheading21{
      padding-left: 0.5rem;
  }
  .name > h2 {
    font-size: 1.1rem;
  }
  .nftboxheading2 {
    padding: 0.2rem 0.5rem;
  }
}
@media (min-width: 289px) and (max-width: 765px) {
  .section1,
  .section2,.section3 {
    width: 93%;
    border-radius: 20px;
    padding: 0.5rem;
    margin-left: 0;
    @apply flex-col gap-10;
  }
  .nfttextbox{
      padding: 0 0.9rem;
      @apply flex-col;
    }
    .nfth1>h1{
      font-size: 1.6rem;
      line-height: 1.9rem;
      text-align: left;
    }
    .div1,.div2{
      width: 100%;
        }
        .div1>p{
          font-size: 1rem;
        }
        .nfttextbox>.div2>p{
          width: 100%;
          font-size: 1rem;

        }
  .walletboxheading {
    width: 100%;
    @apply pl-4 ;
  }
  .walletboxheading>h1{
      padding-bottom: 3rem;
  }
  .walletimg{
      width: 100%;

  }
  .imgboxcard {
    min-height: 30vh;
  }
  .imgboxcard1 {
    @apply flex-col;
  }
  .imgboxcard1 > .cards {
    width: 100%;
    height: 40%;
  }
  .nftboxheading2 {
      padding: 0.5rem 0.5rem;
    }
  
    .nftboxheading2 > h1 {
      width: 100%;
      font-size: 1.25rem;
      line-height: 1.7rem;
    }

  .box {
    width: 100%;
    height: 40%;
    /* justify-content: flex-start; */
  }
  .nftboxheading21{
      padding-left: 0.5rem;
  }
  .nftboxheading21 > p {
    width: 100%;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: left;

  }

  .name > h2 {
    font-size: 1.3rem;
  }
  .nftboxheading2 {
    padding: 0.2rem 0.5rem;
  }
  
}
@media (min-width:540px) {
  .walletimg>img{
      width: 300px;
      height: 300px;
  }
}
@media (min-width: 766px) and (max-width: 912px) {
  .walletboxheading > p{
    width: 90%;
  }
  
  .imgboxcard1{
      @apply flex-row;
    }
  .walletboxheading {
    width: 100%;
    padding: 1rem 0;
    @apply pl-4;
  }
  .section1,
  .section2 {
    width: 90%;
    border-radius: 20px;
    @apply flex-col gap-10; 
  }
 
  .walletimg{
    width: 100%;
    @apply flex items-center justify-center;
  }
  .walletimg>img{
      width: 300px;
      height: 300px;
  }
  .box{
      width: 100%;
  }
  .imgboxcard1 > .cards > .imuu {
    width: 100%;
  }
  .imgboxcard {
    min-height: 35vh;
  }
}
@media (min-width: 913px) and (max-width: 1024px) {
  .section1 .section2{
    width: 95%;
    border-radius: 20px;
    padding-bottom: 1.2rem;
  }
  
  .walletboxheading {
    padding-left: 2rem;
    padding-right: 1rem;
  }
  .imgboxcard {
    min-height: 35vh;
  }
  .nftboxheading2{
      width: 99%;
  }
 
}