/* * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
} */
body {
  @apply min-h-full w-full;
}
.container3 {
  width: 100%;
  height: auto;
  overflow: hidden;
  gap: 40px;
  background-color: #8b56fc;
  padding-bottom: 2rem;
  @apply relative flex flex-col items-center justify-center;
}
.section1 {
  min-height: 270px;
  width: 90%;
  background-color: #8b56fc;
  margin-top: 1rem;
  @apply flex justify-between flex-col overflow-hidden;
}
.tokenheading {
  @apply flex flex-col items-center justify-start p-12;
}
.tokenheading > p {
  letter-spacing: 0.1em;
}
.tokenheading > h1 {
  letter-spacing: 1px;
}

.nftimgbox {
  width: 100%;
  height: 100%;
  /* background-color: #d67373; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.nftimgbox > img {
  width: 319px;
  height: 319px;
filter: drop-shadow(2px 2px 7px  #00000066);
  transition: all ease 2s;
}

/*second section  */
.section2 {
  background: #5b21b6;
  width: 90%;
  min-height: 100vh;
  border-radius: 50px;
  padding: 2rem 2rem;
  box-shadow: 0px 0px 10px 0px #00000066;
  @apply pb-12 overflow-hidden;
}
.nftboxheading2 {
  width: 100%;
  /* padding-top: 2rem; */
  min-height: 100px;
  @apply flex justify-center flex-col items-center;
}
.nftboxheading2 > h1 {
  width: 63.5%;
  text-align: center;
}
.nftboxheading2 > p {
  width: 100%;
  text-align: left;
  letter-spacing: 0.1em;
}
/*second section  */
.imgboxcard {
  width: 100%;
  min-height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.imgboxcard1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 200px;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  /* margin-top: 3rem ; */
  padding: 1rem 0;
  justify-content: center;
}
.imgboxcard1 > .cards {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 20px;
  /* align-items: center; */
  position: relative;
}
.imgboxcard1 > .cards > .imuu {
  height: 100%;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  /* background-color: blue; */
}

.cards > .imuu > .nft2,
.cards > .imuu > .blockchain2,
.cards > .imuu > .wallet2 {
  height: 170px;
  width: 170px;
}
.cards > .imuu > .smartContract {
  width: 150px;
  height: 150px;
}
.cards > .imuu > .study {
  width: 170px;
  height: 146.31px;
}
.cards > .imuu > .certificate {
  height: 150px;
  width: 150px;
}
.box:hover {
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
  padding: 0.2rem 0rem;
}
.box {
  margin-top: 1.5rem;
  height: 100%;
  width: 50%;
  /* background-color: red; */
}
.accordion {
  cursor: pointer;
  padding: 0.5rem 1rem;
  /* margin-top: 1rem; */
  transition: 0.6s ease-in;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  min-width: 200px;
  color: #fff;
  justify-content: space-between;
  display: flex;
  /* background-color: #9c5151; */
  align-items: center;
}
.name {
  display: flex;
  justify-content: first baseline;
  align-items: center;
}
.name > h2 {
  font-weight: 600;
  letter-spacing: 0.1em;
}

.reveal {
  width: 100%;
  min-height: 100px;
  display: block;
  /* margin-top: 0.5rem; */
  padding: 0.3rem 0rem;
  z-index: 999;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.4rem;
  transition: 0.5s ease;
  letter-spacing: 0.1em;
  white-space: pre-line;
}
.indicator {
  padding: 0.3rem 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c4b5fd;
  transition: 0.6s ease;
}
.indicator > svg {
  width: 1.3rem;
  height: auto;
  fill: #000;
  /* padding : 1rem; */
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
}

.section3 {
  width: 90%;
  min-height: 350px;
  background-color: #5b21b6;
  border-radius: 50px;
  overflow: hidden;
  padding-bottom: 1rem;
  display: flex;
  padding: 0 2rem;
  box-shadow: 0px 0px 10px 0px #00000066;
  flex-direction: column;
}
.nftboxheading3 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.7rem 0rem;
  flex-direction: column;
}
.nfth1 {
  width: inherit;
  height: 20px;
  display: flex;
  align-items: center;
  padding: 1.5rem 0;
  justify-content: center;
}
.thirdsec {
  width: 100%;
  min-height: 200px;
  display: flex;
  padding: 2rem 0;
  align-items: center;
  justify-content: center;
}
.accordionsection1 {
  cursor: pointer;
  transition: 0.6s ease-in;
  width: 60%;
  /* background-color: #fff; */
  height: 100%;
  @apply flex flex-col gap-3;
}
.accordionsection2 {
  cursor: pointer;
  /* background-color: rgb(79, 48, 48); */
  width: 40%;
  height: 100%;
  @apply flex items-center justify-center;
}
.accordionsection2 > img {
  width: 250px;
  height: 250px;
filter: drop-shadow(7px 10px 7px  #00000066);

}
.accordion2 {
  cursor: pointer;
  transition: 0.6s ease-in;
  width: 100%;
  @apply rounded-lg;
}
.name2 {
  /* width: 350px; */
  /* background-color: red; */
  display: flex;
  justify-content: flex-start;
  align-items: center;

  /* @apply flex items-center; */
}
.name2 > h2 {
  /* font-size: 1.2rem; */
  font-weight: 600;
  letter-spacing: 1px;
}
.title2 {
  min-width: 200px;
  color: #fff;
  justify-content: space-between;
  display: flex;
  /* background-color: #ff3; */
  align-items: flex-start;
  /* gap: 10px; */
}
.title2 > h2 {
  text-align: left;
}
.reveal > .para {
  /* background-color: red; */
  letter-spacing: 0.1em;
  white-space: pre-line;
  font-weight: 400;
  width: 100%;
}
.indicator1 {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.6s ease;
}
.indicator1 > svg {
  width: 2.5rem;
  height: 2.5rem;
  fill: #c4b5fd;
  /* padding : 1rem; */
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
}
.nftboxheading4 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 2rem 0rem;
  margin-left: 1rem;
  flex-direction: column;
}
.btn {
  padding: 0.8rem 1.7rem;
  height: 50px;
  width: 252px;
  box-shadow: 0px 4px 15px 0px #2e2e2e26;
  background: #f3cc2fcc;
  border-radius: 30px;
  color: aliceblue;
  cursor: pointer;
  margin-top: 1rem;
filter: drop-shadow(7px 10px 7px  #00000066);

  @apply flex items-center justify-center;
}

@media screen and (max-width: 280px) {
  .btn {
    width: 200px;
  }
  .nftboxheading2 {
    padding: 0.5rem 0rem;
  }
  .section1,
  .section2,
  .section3 {
    width: 90%;
    border-radius: 10px;
    padding: 0 0.4rem;
  }

  .nftboxheading3 {
    padding: 1rem 0;
    width: 100%;
    @apply flex-col justify-start items-start pt-3;
  }
  .nftboxheading4 {
    /* padding-left: 1rem; */
    padding: 1rem 0;
    width: 90%;
    @apply flex-col justify-start items-start pt-3;
  }

  .nftboxheading3 > p {
    font-size: 1rem;
  }
  .tokenheading {
    padding-left: 0.8rem;
    @apply justify-start items-start pt-2;
  }
  .tokenheading > p {
    font-size: 1rem;
    width: 100%;
  }
  .tokenheading > h1 {
    font-size: 1.6rem;
  }
  .nftimgbox {
    width: 100%;
  }
  .nftimgbox > img {
    width: 200px;
    height: 200px;
  }
  .tokenheading {
    @apply p-3;
  }
  .box {
    height: 40%;
    width: 100%;
  }
  .imgboxcard1 > .cards {
    width: 100%;
    gap: 0;
    flex-direction: column;
  }
  .imgboxcard1 > .cards > .imuu {
    width: 100%;
  }
  .nftboxheading2 > h1 {
    width: 100%;
    font-size: 0.94rem;
    line-height: 1.5rem;
  }
  .nftboxheading2 > p {
    font-size: 0.9rem;
    line-height: 1.4rem;
  }
  .name > h2 {
    font-size: 0.9rem;
    letter-spacing: 0.5px;
  }
  .indicator > svg {
    width: 1rem;
  }
  .imgboxcard1 {
    flex-direction: column;
  }

  .nfth1 > h1 {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
  .thirdsec {
    flex-direction: column;
    gap: 40px;
  }
  .accordionsection1,
  .accordionsection2 {
    width: 100%;
  }
  .accordionsection2 > img {
    width: 150px;
    height: 150px;
  }
  .name2 > h2 {
    font-size: 1.1rem;
  }
  .title2 {
    min-width: 220px;
  }
}
@media (min-width: 289px) and (max-width: 765px) {
  .btn {
    width: 200px;
  }
  .section1,
  .section2,
  .section3 {
    width: 90%;
    border-radius: 10px;
    padding: 0 0.4rem;
  }

  .nftboxheading3 {
    padding: 1rem 0;
    width: 100%;
    @apply flex-col justify-start items-start pt-3;
  }
  .nftboxheading4 {
    /* padding-left: 1rem; */
    padding: 1rem 0;
    width: 90%;
    @apply flex-col justify-start items-start pt-3;
  }

  .nftboxheading3 > p {
    font-size: 1rem;
  }
  .tokenheading {
    padding-left: 0.8rem;
    @apply justify-start items-start pt-2;
  }
  .tokenheading > p {
    font-size: 1rem;
    width: 100%;
    /* color: #8b56fc; */
  }
  .tokenheading > h1 {
    font-size: 1.6rem;
  }
  .nftimgbox {
    width: 100%;
  }
  .nftimgbox > img {
    width: 200px;
    height: 200px;
  }
  .tokenheading {
    @apply p-3;
  }
  .box {
    height: 40%;
    width: 100%;
  }
  .imgboxcard1 > .cards {
    width: 100%;
    gap: 0;
    flex-direction: column;
    /* background-color: red; */
  }
  .imgboxcard1 > .cards > .imuu {
    width: 100%;
  }
  .nftboxheading2 {
    padding: 0.5rem 0.5rem;
  }

  .nftboxheading2 > h1 {
    width: 100%;
    font-size: 1.25rem;
    line-height: 1.7rem;
  }
  .nftboxheading2 > p {
    font-size: 1rem;
    line-height: 1.4rem;
  }
  .name > h2 {
    font-size: 1.3rem;
    letter-spacing: 0.5px;
  }
  .indicator > svg {
    width: 1rem;
  }
  .imgboxcard1 {
    flex-direction: column;
  }
  .nfth1 > h1 {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
  .thirdsec {
    flex-direction: column;
    gap: 40px;
  }
  .accordionsection1,
  .accordionsection2 {
    width: 100%;
  }
  .accordionsection2 > img {
    width: 150px;
    height: 150px;
  }
  .name2 > h2 {
    font-size: 1.2rem;
  }

  .title2 {
    min-width: 220px;
  }
}
@media (min-width: 766px) and (max-width: 912px) {
  .imgboxcard1 > .cards > .imuu {
    width: 40%;
  }
}
