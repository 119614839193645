/* 
    margin: 0;
    padding: 0;
    box-sizing: border-box;

} */
body {
    @apply min-h-full w-full;
  }
  .container7 {
    width: 100%;
    min-height: 200vh;
    overflow: hidden;
    gap: 40px;
    background: #FACC15;
    padding-bottom: 2rem;
    @apply relative flex flex-col items-center justify-center;
  }
  .section3 {
    width: 90%;
    min-height: 30vh;
    background-color: #eab308;
    border-radius: 50px;
    overflow: hidden;
    padding-bottom: 1rem;
    display: flex;
    padding: 2rem 2rem;
    padding-bottom: 2rem;
    box-shadow: 0px 0px 10px 0px #00000066;
    flex-direction: column;
  }
  .section1 {
    min-height: 270px;
    width: 90%;
    background-color: #FACC15;   
    margin-top: 1rem;
    @apply flex justify-between flex-col  overflow-hidden;
  }
  .tokenheading {
    @apply flex flex-col items-center justify-start p-12;
  }
  .tokenheading > p {
    letter-spacing: 0.1em;
    color: #000;
  }
  .tokenheading > h1 {
    letter-spacing: 1px;
  }
  
  .bitcoinimgbox{
    width: 100%;
    height: 320px;
    background-image: url(../../DoorsImages/Doorimg7/20190107_020\ 1.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .bitcoinimgbox > img {
    width: 319px;
    height: 319px;
    filter: contrast(1);
    transition: all ease 2s;
  }
  .section2{
    width: 90%;
    min-height: 100vh;
    border-radius: 50px;
    box-shadow: 0px 0px 10px 0px #00000080;

    background-color: #eab308;
    @apply flex flex-col pb-12 overflow-hidden;

  }
  .cards,.cardsss{
    width: 100%;
    min-height: 200px;
padding: 3rem 3rem;
    @apply flex justify-between items-center;

  }
  .indicator {
    padding: 0.3rem 1rem;
    display: flex;
    margin-top: 0.8rem;
    align-items: center;
    justify-content: center;
    background-color: #FACC15;
    transition: 0.6s ease;
  }
  .indicator > svg {
    width: 1.3rem;
    height: auto;
    fill: #000;
    /* padding : 1rem; */
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
  }
  .text{
width: 50%;
height: 100%;
padding: 3rem 2rem;
@apply flex flex-col justify-center items-start ;
  }
  .ccc{
    width: 100%;
    @apply flex justify-between  flex-col;
  }
  .textac{
    width: 55%;
    height: 100%;
    padding: 0rem 2rem;
    @apply flex flex-col justify-start items-start ;
      }
  .text>h1,.textac>h1{
text-align: left;
  }
.text p>.textac>p{
    width: 100%;
    white-space: pre-line;
    line-break:normal;
    /* transition: all cubic-bezier(0.19, 1, 0.22, 1) 1s; */
    transition: 0.7s ease;
}
.cardimgss{
    width:40%;
    height: 100%;
    @apply flex justify-start items-center;
}
  .cardimgs{
    width: 45%;
    height: 100%;
    @apply flex justify-center items-center;
  }
  .cardimgs> img{
    width: 410px;
    height: 410px;
  }
  .cardimgss>img{
    width: 410px;
    height: 350px;
  }
 
@media screen and (max-width:280px){
    .section1,.section2,.section3 {
        width: 94%;
    border-radius: 10px;
    }
    .tokenheading{
        padding: 0.3rem 1rem ;
        align-items: flex-start;

    }
    .tokenheading>h1{
        text-align: left;
    }
    .tokenheading>p{
        text-align: left;
    }
    .cards,.cardsss{
        flex-direction: column;
        padding:1rem 0.3rem;
    }
    .cardsss{
        flex-direction: column-reverse;
    }
    .text,.textac{
        width: 100%;
        padding: 0.3rem;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .cardimgs,.cardimgss{
        width: 100%;
    }
    .cardimgs>img{
        width: 250px;
        height: 250px;
    }
    .text>h1{
        font-size: 1.5rem;

    }
    .ccc>div>h1{
        font-size: 1.35rem;
        line-height: 1.6rem;
    }
    
}
@media (min-width:289px) and (max-width:765px){
    .section1,.section2,.section3{
        width: 94%;
    border-radius: 10px;
    }
    .tokenheading{
        padding: 0.3rem 1rem ;
        align-items: flex-start;

    }
    .tokenheading>h1{
        text-align: left;
    }
    .tokenheading>p{
        text-align: left;
    }
    .cards,.cardsss{
        flex-direction: column;
        padding:1rem 0rem;
        padding-left: 1rem;
        gap: 20px;
    }
    .indicator{
        margin-top: 0.3rem;
    }
    .cardsss{
        flex-direction: column-reverse;
    }
    .text,.textac{
        width: 100%;
        padding: 0.3rem;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .cardimgs,.cardimgss{
        width: 100%;
    }
    .cardimgs>img{
        width: 250px;
        height: 250px;
    }
    .ccc>div>h1{
        font-size: 1.5rem;
        line-height: 1.8rem;
    }
    
}
@media (min-width:767px) and (max-width:912px){
    .section1,.section2,.section3{
        width: 96%;
    border-radius: 10px;
    }
    .tokenheading{
        padding: 0.3rem 1rem ;
        align-items: flex-start;

    }
    .tokenheading>h1{
        text-align: left;
    }
    .tokenheading>p{
        text-align: left;
    }
    .cards,.cardsss{
        /* flex-direction: column; */
        padding:1rem 0.8rem;
        padding-left: 1rem;
        gap: 20px;
    }
    .indicator{
        margin-top: 0.3rem;
    }
    .cardsss{
        /* flex-direction: column-reverse; */
    }
    .text,.textac{
        width: 100%;
        padding: 0.3rem;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .cardimgs,.cardimgss{
        width: 100%;
    }
    .cardimgs>img{
        width: 250px;
        height: 250px;
    }
    .ccc>div>h1{
        font-size: 1.5rem;
        line-height: 1.8rem;
    }
    
}