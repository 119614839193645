/* @import url('https://fonts.googleapis.com/css2?family=Raleway'); */
/* @import url('https://fonts.googleapis.com/css2?family=Comfortaa'); */
/* @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Fredoka:wght@300..700&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

body {
  font-family: 'Nunito', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

*::-webkit-scrollbar{
  width: 7px;
  /* color: #082f49c7; */
}
*::-webkit-scrollbar-thumb{
  scroll-behavior: smooth;
  background-color: #7f7f7f; 
  border-radius: 10px
}
*::-webkit-scrollbar-track{
  background-color: #f5f2ed;
  /* color: #8ba3b7c7; */
}


.wrap1 {
  /* width: 60%; */
  min-height: 100vh;
  /* background-color: rebeccapurple; */
  @apply my-12  flex flex-col items-center justify-between;
}
.bb1box {
  min-height: 100vh;
  /* background-color: red; */
  width: 60%;
  @apply flex flex-col items-center justify-start gap-4;
}
.giftwrapbox {
    background-color: #dce9ef;
  width: 80%;
  position: relative;
  min-height: 50vh;
  box-shadow: 2px 2px 3px 2px rgb(0, 0, 0, 0.3);
/* background-color: red; */
  background-position: center;

  z-index: 11;
 
  @apply rounded-xl overflow-hidden flex items-center justify-center p-4;
}
.flowerimg {
  z-index: 99999;
  position: absolute;
  top: 50%;
  height: 100%;
  /* z-index: 11; */
  left: 50%;
  transition: all 0.5s ease;
  transform: translate(-50%, -50%);
}
.rectange11 {
  width: 100%;
  min-height:90%;
  /* background-color: red; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}
.giftoverlay {
  width: 80%;
  min-height: 80%;
  background-color: rgba(15, 54, 54);
  opacity: 0;
  transition: opacity 2s;
}

.rectange1 {
  width: 90%;
  height: 600px;
  overflow-y: hidden;
  background-color: #fff;
  box-shadow: 0px 0px 3px rgb(0, 0, 0, 0.8);
  position: relative;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 300px;
  @apply flex justify-center items-center;
}
.rectange2,
.rectange3,
.giftoverlay {
  transition: all 0.5s ease;
}
.rectange2 {
  width: 88%;
  height: 600px;
  border-radius: 300px;
  box-shadow: 0px 0px 3px rgb(0, 0, 0, 0.8);
  background-color: #eef4f9;

  position: absolute;
  top: -49.5%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.rectange3 {
  width: 88%;
  height: 600px;
  border-radius: 300px;
  /* background-color: #6b6de3; */
  /* background-color: #fac07e; */
  /* background-color: #ff91b8; */
  background-color: #eef4f9;
  box-shadow: 2px 2px 3px rgb(0, 0, 0, 0.8);

  position: absolute;
  bottom: -50%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.giftwrapingbox1 {
  width: 100%;
  /* filter: drop-shadow(10px 10px 10px 2px #0000); */
  height: 100%;
  @apply relative py-2 flex items-center justify-between rounded-2xl overflow-hidden bg-sky-950 p-0 pl-16 text-white shadow-md;
}
.giftwrapingbox1 > button {
  /* z-index: 10; */
  /* @apply absolute left-80 top-24 rounded-2xl  bg-white px-5 py-1 font-bold text-yellow-500 */
}
.giftbox2 {
  width: 50%;
  height: 100%;
  @apply relative flex flex-grow flex-col;
}

.giftbox3 {
  z-index: 1;
  /* background-color: red; */
  @apply relative flex w-6/12 h-full justify-center  items-center overflow-hidden;
}
@media screen and (max-width: 280px) {
  .giftwrapbox {
    width: 90%;
    /* margin-top: -2rem; */
    /* top: 67%; */
  }
}
@media (min-width: 280px) and (max-width: 376px) {
  .giftwrapingbox1 {
    flex-direction: column-reverse;
    /* padding-left: 1rem; */
    padding: 1rem 0.5rem;
    border-radius: 0.5rem;
    /* margin: 1rem 1rem; */
    width: 98%;
  }
  .bb1box {
    min-height: 70vh;
    /* background-color: #dbb8b8;  */
  }
  .wrap1 {
    min-height: 80vh;
  }

  .giftwrapbox {
    width: 100%;
  }
  .giftbox2,
  .giftbox3 {
    width: 100%;
    height: 50%;
  }
  .giftbox2 {
    padding: 0.5rem 0.4rem;
    @apply justify-center;
  }
  .giftbox2 > p {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  .giftbox2 > a {
    padding-bottom: 2rem;
  }
  .giftwrapingbox1 > button {
    display: none;
  }
}

@media (min-width: 378px) and (max-width: 765px) {
  .giftwrapingbox1 {
    flex-direction: column-reverse;
    /* padding-left: 1rem; */
    padding: 1rem 1rem;
    width: 90vw;
    border-radius: 0.5rem;
  }
  .bb1box {
    min-height: 70vh;
    /* background-color: #dbb8b8; */

    /* justify-content: space-between; */
  }
  .wrap1 {
    min-height: 80vh;
    /* background-color: #b36f6f; */
  }
  .giftwrapbox {
    /* left: 40%; */
    width: 100%;
    /* padding: 1rem 0.5rem; */
    /* margin-bottom: 2rem; */
    /* top: 50%; */
  }
  .giftbox2 {
    width: 100%;
    height: 50%;
  }
  .giftbox3 {
    width: 50%;
    height: 50%;
  }
  .giftbox2 {
    padding: 0.5rem 0.4rem;
    @apply justify-center;
  }
  .giftbox2 > p {
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  .giftbox2 > a {
    padding-bottom: 2rem;
  }
  .giftwrapingbox1 > button {
    display: none;
  }
}
@media (min-width: 540px) {
  .giftwrapbox {
    width: 100%;
    /* margin-top: -2rem; */
    /* top: 67%; */
  }
}
@media (min-width: 766px) and (max-width: 912px) {
  .giftwrapbox {
    /* top: 40%; */
    /* width:725px ; */
  }
  .bb1box {
    min-height: 60vh;
    /* background-color: #dbb8b8; */

    /* justify-content: space-between; */
  }
  .giftwrapingbox1 {
    width: 90%;
  }
  .wrap1 {
    min-height: 60vh;
    /* background-color: #b36f6f; */
  }
}
@media (min-width: 913px) and (max-width: 1024px) {
  .giftwrapbox {
    /* top: 65%; */
    /* width: 725px; */
  }
  .bb1box {
    min-height: 60vh;
    /* background-color: #dbb8b8; */

    /* justify-content: space-between; */
  }
  .wrap1 {
    min-height: 50vh;
    /* background-color: #b36f6f; */
    justify-content: start;
  }
}
