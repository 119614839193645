body {
  @apply min-h-full w-full;
}
.container8 {
  width: 100%;
  height: auto;
  overflow: hidden;
  gap: 40px;
  background: #a8a29e;
  padding-bottom: 2rem;
  @apply relative flex flex-col items-center justify-center;
}
.section1 {
  min-height: 270px;
  width: 90%;
  background-color: #a8a29e;
  margin-top: 1rem;
  @apply flex justify-center flex-col items-center overflow-hidden;
}
.door8sec1box {
  /* gap: 18px; */
  @apply flex justify-center flex-col items-center;
}
.door8sec1box > p {
  padding-bottom: 2rem;
}
.section2 {
  min-height: 100vh;
  width: 90%;
  background: #44403c;
  border-radius: 50px;
  gap: 18px;
  @apply flex items-center flex-col overflow-hidden;
}
.nftboxheading2 {
  width: 100%;
  min-height: 100px;
  @apply flex justify-center items-center pt-5;
}
.nftboxheading2 > h1 {
  line-height: 2.7rem;
  width: 85%;
}
.nftboxheading21 {
  width: 80%;
  min-height: 100px;
  padding-bottom: 2rem;
  @apply flex justify-start items-start pl-12;
}
.nftboxheading21 > p {
  width: 90%;
}
.accordionboxcard {
  width: 100%;
  min-height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.imgboxcard1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 300px;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px 160px;
  justify-content: center;
}
.imgboxcard1 > .cards {
  width: 80%;
  height: 100%;
  /* background-color: #fff; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
}
.imgboxcard1 > .cards > .imuu {
  height: 70%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 2rem;
  /* background-color: red; */
}

.box {
  height: 30%;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #A3E63580; */
  background: #a8a29e;
  transition: 0.6s ease-in;
  padding: 1rem;
  box-shadow: 0px 0px 10px 0px #00000040;

  @apply rounded-xl;
}

.accordion {
  cursor: pointer;
  height: 25%;
  transition: 0.6s ease-in;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  min-width: 200px;
  /* color: #fff; */

  justify-content: space-between;
  display: flex;
  align-items: center;
}
.cards > .box:hover {
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
  /* background-color: #505B6B; */
  /* padding: 0.3rem 0.3rem; */
}
.cards > .imuu > img {
  width: 96.5%;
  height: 400px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px #00000040;
}
.indicator {
  display: flex;
  justify-content: center;
  align-items: center;
}
.name {
  /* width: 350px; */
  display: flex;
  justify-content: first baseline;
  align-items: center;

  /* @apply flex items-center; */
}
.name > h2 {
  font-size: 1.5rem;
  font-weight: 600;
  letter-spacing: 1px;
  font-weight: 500;
}

.reveal {
  width: 100%;
  /* min-height: 100px; */
  display: block;
  /* margin-top: 0.5rem; */
  padding: 0.3rem 0rem;
  z-index: 999;
  font-size: 1.1rem;
  font-weight: medium;
  line-height: 1.4rem;
  white-space: pre-line;
  transition: 0.5s ease;
  letter-spacing: 0.1em;
  /* background-color: red; */
}
.indicator {
  padding: 0.3rem 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #44403c;
  /* @apply rounded-full; */
}
.indicator > svg {
  width: 1.5rem;
  height: auto;
  fill: #fff;
  /* padding : 1rem; */
}
.section3 {
  min-height: 270px;
  width: 90%;
  background: #44403c;
  border-radius: 50px;
  padding: 2rem;
  gap: 18px;
  @apply flex justify-start flex-col items-start overflow-hidden;
}
.door8img1 {
  width: 462px;
  height: 225px;
}
.door8img2 {
  width: 555px;
  height: 311px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px #00000040;
}
.btn {
  width: 252px;
  height: 53px;
  background: #f3cc2fcc;
  border-radius: 30px;
  color: #fff;
  font-weight: 600;
  /* text-decoration: underline; */
  box-shadow: 0px 4px 15px 0px #2e2e2e26;
}
.section3 > .door8sec3img {
  width: 507px;
  height: 256px;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px #00000040;
}

@media screen and (max-width: 280px) {
  .section1,
  .section2,
  .section3 {
    border-radius: 10px;
    @apply flex-col;
  }
  .section1 {
    padding: 0.8rem;
  }
  .section3 > .door8sec3img,
  .door8img1,
  .door8img2 {
    width: 300px;
    height: 177px;
  }
  .nftboxheading2 {
    padding: 0.5rem 0rem;
  }
  .nftboxheading2 > h1 {
    width: 100%;
    font-size: 0.94rem;
    line-height: 1.5rem;
  }
  .walletboxheading {
    width: 100%;
    @apply pl-4;
  }
  .imgboxcard1 > .cards > .imuu {
    width: 115%;
  }
  .cards > .imuu > img {
    width: 100%;
    height: 200px;
    border-radius: 10px;
  }
  .walletimg {
    width: 100%;
  }
  .accordionboxcard {
    min-height: 100vh;
  }
  .imgboxcard1 {
    gap: 10px;
    min-height: 100vh;
    @apply flex-col;
  }
  .imgboxcard1 > .cards {
    width: 100%;
    height: 40%;
  }

  .box {
    width: 90%;
    height: 40%;
    /* justify-content: flex-start; */
  }
  .nftboxheading21 > p {
    width: 100%;
    font-size: 0.9rem;
    text-align: left;
    line-height: 1.5rem;
  }
  .nftboxheading21 {
    padding-left: 0rem;
  }
  .name > h2 {
    font-size: 1.1rem;
  }
  .section3 {
    padding: 1rem;
  }
  .btn {
    width: 192px;
  }
  .nftboxheading2 {
    padding: 0.2rem 0.5rem;
  }
  .indicator {
    padding: 0.3rem 0.2rem;
  }
}
@media (min-width: 289px) and (max-width: 765px) {
  .section1,
  .section2,
  .section3 {
    border-radius: 10px;
    @apply flex-col;
  }
  .section1 {
    padding: 0.8rem;
  }
  .section3 > .door8sec3img,
  .door8img1,
  .door8img2 {
    width: 300px;
    height: 177px;
  }
  .nftboxheading2 {
    padding: 0.5rem 0.5rem;
  }
  .nftboxheading2 > h1 {
    width: 100%;
    font-size: 1.2rem;
    line-height: 1.5rem;
    /* text-align: left; */
  }
  .walletboxheading {
    width: 100%;
    @apply pl-4;
  }
  .imgboxcard1 > .cards > .imuu {
    width: 108%;
  }
  .cards > .imuu > img {
    width: 100%;
    height: 200px;
    border-radius: 10px;
  }
  .walletimg {
    width: 100%;
  }
  .accordionboxcard {
    min-height: 100vh;
  }
  .imgboxcard1 {
    gap: 10px;
    min-height: 100vh;
    @apply flex-col;
  }
  .imgboxcard1 > .cards {
    width: 100%;
    height: 40%;
  }

  .box {
    width: 90%;
    height: 40%;
    /* justify-content: flex-start; */
  }
  .nftboxheading21 > p {
    width: 100%;
    font-size: 0.9rem;
    text-align: left;
    line-height: 1.5rem;
  }
  .nftboxheading21 {
    padding-left: 0rem;
    width: 85%;
  }
  .name > h2 {
    font-size: 1.1rem;
  }
  .section3 {
    padding: 1rem;
  }
  .btn {
    width: 192px;
  }
  .nftboxheading2 {
    padding: 0.2rem 0.5rem;
  }
  .indicator {
    padding: 0.3rem 0.2rem;
  }
}
