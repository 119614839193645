/* .hiden{

    opacity: 0;
    filter: blur(5px);
    transform: translateX(-100%);
    transition: all 1s;
   
}






.show
{
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}



.logo:nth-child(2){
transition-delay: 200ms;
}
.logo:nth-child(3){
transition-delay: 400ms;
} */



.backgroundimg1 {
    background-image: linear-gradient(
        140deg,
        hsl(55deg 75% 87%) 0%,
        hsl(54deg 76% 90%) 6%,
        hsl(54deg 78% 93%) 13%,
        hsl(53deg 79% 96%) 20%,
        hsl(53deg 80% 99%) 28%,
        hsl(300deg 100% 100%) 36%,
        hsl(300deg 100% 100%) 45%,
        hsl(300deg 100% 100%) 55%,
        hsl(300deg 100% 100%) 64%,
        hsl(0deg 0% 100%) 72%,
        hsl(52deg 84% 99%) 78%,
        hsl(52deg 83% 97%) 83%,
        hsl(53deg 82% 96%) 87%,
        hsl(53deg 82% 94%) 90%,
        hsl(53deg 81% 93%) 93%,
        hsl(53deg 80% 92%) 95%,
        hsl(53deg 79% 91%) 97%,
        hsl(53deg 79% 90%) 99%,
        hsl(53deg 79% 89%) 100%
      );
}