@tailwind base;
@tailwind components;
@tailwind utilities;


input.defaultCheckbox::before {
	/* content: url(../src/assets/svg/checked.svg);  */
    content: url(../src/sections_landing/dashboard/assets/svg/checked.svg);
	color: white;
	opacity: 0; 
	height: 16px; 
	width: 16px;
	position: absolute; 
	left: 50%;
	transform: translate(-50%, 0px);
} 

input:checked.defaultCheckbox::before { 
	opacity: 1;
}