body {
  @apply min-h-full w-full;
}
.container4 {
  width: 100%;
  min-height: 200vh;
  overflow: hidden;
  gap: 40px;
  background-color: #27272a;
  padding-bottom: 2rem;
  @apply relative flex flex-col items-center justify-center;
}
.section1 {
  box-shadow: 0px 0px 10px 0px #00000066;
  background: #09090b;
  width: 90%;
  min-height: 370px;
  margin-top: 2rem;
  border-radius: 50px;
  position: relative;
  @apply flex justify-between  overflow-hidden;
}
.walletboxheading {
  width: 69%;
  min-height: 100%;
  display: flex;
  /* background-color: red; */
  @apply flex-col justify-start items-start pl-12 pt-2;
}
.walletimgbox {
  width: 31%;
  min-height: 100%;
  position: relative;
  box-shadow: 7px 7px 10px 0px #00000066;
}
.walletimgbox > .web3img1 {
  width: 100%;
  height: 103%;
  object-fit: cover;
  transition: all ease 2s;
  /* margin-left: -2rem; */
}

.cards>.imuu >img{
filter: drop-shadow(7px 10px 7px  #22212166);

}

.webimg2{
  width:130px;
  height: 120px;
  z-index: 99999;
  bottom: 15%;
  right: 17%;
  background-color: #09090b;
  position: absolute;
}
.section2 {
  background: #09090b;
  width: 90%;
  min-height: 100vh;
  border-radius: 50px;
  box-shadow: 0px 0px 10px 0px #00000066;
  @apply pb-12 overflow-hidden;
}
.section3 {
  background: #09090b;
  width: 90%;
  min-height: 40vh;
  border-radius: 50px;
  color: #fff;
  box-shadow: 0px 0px 10px 0px #00000066;
  @apply p-12 overflow-hidden;
}
.nftboxheading2 {
  width: 100%;
  padding: 1rem 2rem;
  min-height: 100px;
  @apply flex justify-start items-center;
}

.nftboxheading2 > p {
  width: 90%;
}

/*second section  */
.imgboxcard {
  width: 100%;
min-height: 50vh;
  /* background-color: skyblue; */
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* margin-bottom: 1.5rem; */
}

.imgboxcard1,.imgboxcard2{
width: 100%;
height: 100%;
display: flex;
/* align-items: center; */
justify-content: center;
gap: 100px;
padding: 2rem 0;
}

.imgboxcard2{
  /* background-color: rebeccapurple; */
  justify-content: center;
}
.imgboxcard1 > .cards,
.imgboxcard2 > .cards {
  width: 43%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.imgboxcard1 > .cards > .imuu,
.imgboxcard2 > .cards > .imuu
 {
  width: 100%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
}

.box {
  height: 30%;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.cards > .imuu > .padlock,
.cards > .imuu > .curency,
.cards > .imuu > .receipt,
.cards > .imuu > .mask1 {
  height: 185px;
  width: 185px;
}
.cards > .imuu > .baht {
  width: 185px;
  height: 185px;
}
.cards > .imuu > .mask2 {
  width: 185px;
  height: 185px;
}
.accordion {
  cursor: pointer;
  height: 25%;
  padding: 0.5rem 0rem;
  transition: 0.6s ease-in;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  color: #fff;
  justify-content: space-between;
  display: flex;
  align-items: center;
  transition: 0.6s ease-in;
  padding: 1rem ;
  background: #27272a;
  @apply rounded-xl;

}
.cards:hover {
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
  /* background-color: #505B6B; */
  padding: 0.1rem 0rem;
}

.indicator {
  display: flex;
  justify-content: center;
  align-items: center;
}
.name {
  /* width: 350px; */
  display: flex;
  justify-content: first baseline;
  align-items: center;

  /* @apply flex items-center; */
}
.name > h2 {
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 1px;
}

.reveal {
  width: 100%;
  min-height: 100px;
  display: block;
  /* margin-top: 0.5rem; */
  padding: 0.3rem 0rem;
  z-index: 999;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.4rem;
  transition: 0.5s ease;
  letter-spacing: 0.1em;
  /* background-color: red; */
}
.indicator {
  padding: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #09090b;
  @apply rounded-full;
}
.indicator > svg {
  width: 1rem;
  height: auto;
  fill: #fff;
  /* padding : 1rem; */
}

@media screen and (max-width: 280px) {
  .section1,
  .section2,.section3 {
    /* width: ; */
    border-radius: 10px;
    padding: 0.4rem;
    @apply flex-col gap-10;
  }
  .section3 {
    /* width: ; */
    border-radius: 10px;
    padding: 1rem;
    @apply flex-col gap-10;
  }
  .walletboxheading {
    width: 100%;
    /* padding: 1rem 0; */
    @apply pl-4;
  }
  .walletimgbox {
    width: 100%;
  }
  .walletimgbox > .web3img1 {
    width: 100%;
    height: 100%;
  }
  .imgboxcard {
    min-height: 100vh;
  }
  .imgboxcard1 {
    gap: 10px;
    min-height: 100vh;
    @apply flex-col;
  }
  .imgboxcard1 > .cards {
    width: 100%;
    height: 40%;
  }

  .box {
    width: 100%;
    height: 40%;
    /* justify-content: flex-start; */
  }
  .nftboxheading2 > p {
    width: 100%;
    font-size: 0.9rem;
    text-align: left;
    line-height: 1.5rem;
  }
.webimg2{
  height: 85px;
  width: 100px;
  right: 16%;
}
  .name > h2 {
    font-size: 1.1rem;
  }
  .nftboxheading2 {
    padding: 0.2rem 0.5rem;
  }
}
@media (min-width: 289px) and (max-width: 765px) {
  .section1,
  .section2{
    /* width: ; */
    border-radius: 20px;
    padding: 0.5rem;
    @apply flex-col gap-10;
  }
  .section3{
    /* width: ; */
    border-radius: 20px;
    padding: 1.3rem;
    @apply flex-col gap-10;
  }
  .walletboxheading {
    width: 100%;
    @apply pl-4;
  }
  .walletimgbox > .web3img1 {
    height: 400px;
    width: 370px;
  }
  .walletimgbox {
    width: 100%;
    @apply flex items-center justify-center;
  }
  .imgboxcard {
    min-height: 100vh;
  }
  .imgboxcard1 {
    @apply flex-col;
  }
  .imgboxcard1 > .cards {
    width: 100%;
    height: 40%;
  }

  .box {
    width: 100%;
    height: 40%;
    /* justify-content: flex-start; */
  }
  .nftboxheading2 > p {
    width: 100%;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: left;

  }

  .name > h2 {
    font-size: 1.3rem;
  }
  .nftboxheading2 {
    padding: 0.2rem 0.5rem;
  }
  /* .webimg2{
    right: 52%;
    bottom: 20%;
  } */
}

@media (min-width: 766px) and (max-width: 912px) {
  .walletboxheading > p {
    width: 90%;
  }
  .webimg2{
    right: 35%;
    bottom: 20%;
  }
  .walletboxheading {
    width: 100%;
    padding: 1rem 0;
    @apply pl-4;
  }
  .section1,
  .section2 {
    width: 95%;
    border-radius: 20px;
    @apply flex-col gap-10; 
  }
  .section3{
    /* width: ; */ width: 95%;
    border-radius: 20px;
    padding: 1.3rem;
    min-height: 20vh;
    @apply flex-col gap-10;
  }
  .walletimgbox > .web3img1 {
    height: 488px;
    width: 416px;
  }
  .section2{
    padding: 0 0.3rem;
  }
  .walletimgbox {
    width: 100%;
    @apply flex items-center justify-center;
  }
  .imgboxcard1 > .cards > .imuu {
    width: 100%;
  }
  .imgboxcard {
    min-height: 40vh;
  }
  /* .imgboxcard1 > .cards{
    width: 40%;
  } */
  .nftboxheading2{
    padding: 3rem 1rem;
  }
  .nftboxheading2>p{
    width: 100%;
   
  }
  .reveal{
    font-size: 1rem;
  }
}
@media (min-width: 913px) and (max-width: 1024px) {
  .section1,.section2 {
    width: 95%;
    border-radius: 20px;
    padding-bottom: 1.2rem;
  }
  .section3 {
    width: 95%;
    border-radius: 20px;
  }
  .walletboxheading {
    padding-left: 2rem;
    padding-right: 1rem;
  }
  .imgboxcard {
    min-height: 35vh;
  }
  .walletimgbox > .web3img1 {
    height: 488px;
    width: 416px;
  }
  .webimg2{
    width: 100px;
    height: 80px;
    bottom: 32%;
  }
}
