body {
    @apply min-h-full w-full;
  }
  .container14 {
    width: 100%;
    min-height: 200vh;
    overflow: hidden;
    gap: 40px;
    background-color: #27272a;
    padding-bottom: 2rem;
    @apply relative flex flex-col items-center justify-center;
  }
  .section1 {
    box-shadow: 0px 0px 10px 0px #00000066;
    background: #09090b;
    width: 90%;
    min-height: 260px;
    margin-top: 2rem;
    border-radius: 50px;
    position: relative;
    @apply flex justify-between items-center overflow-hidden;
  }
  .section11 {
    box-shadow: 0px 0px 10px 0px #00000066;
    background: #09090b;
    width: 90%;
    min-height: 360px;
    margin-top: 2rem;
    border-radius: 50px;
    position: relative;
    @apply flex justify-between items-start overflow-hidden;
  }
  .section1aa {
    box-shadow: 0px 0px 10px 0px #00000066;
    background: #09090b;
    width: 90%;
    min-height: 350px;
    /* margin-top: 1rem; */
    border-radius: 50px;
    position: relative;
    @apply flex justify-between items-center overflow-hidden;
  }
  .walletboxheading {
    width: 70%;
    min-height: 100%;
    /* background-color: red; */
    padding-bottom: 2rem;

    @apply flex flex-col justify-start items-start pl-12 pt-2;
  }
  .walletboxheading19{
    width: 90%;
    min-height: 100%;
    /* background-color: red; */
    padding-bottom: 2rem;
    @apply flex flex-col justify-start items-start pl-12;
  }
  .walletimgbox ,.walletimgbox1{
    width: 30%;
    min-height: 100%;
    /* background-color: #fff; */
    @apply flex justify-center items-center;
  }
  .walletimgbox1>img{
height: 340px;
width: 370px;
  }
  .walletimgbox> img {
    height: 416px;
    width: 416px;
    transition: all ease 2s;
    /* margin-left: -2rem; */
  }
  
  .cards>.imuu >img{
  filter: drop-shadow(7px 10px 7px  #22212166);
  
  }
  

  .section2 {
    background: #09090b;
    width: 90%;
    min-height: 200vh;
    border-radius: 50px;
    box-shadow: 0px 0px 10px 0px #00000066;
    @apply pb-12 overflow-hidden flex flex-col items-center;
  }
  .nftboxheading2 {
    width: 100%;
    padding: 1rem 2rem;
    min-height: 100px;
    @apply flex justify-center items-center;
  }
 
  .hr1{
    margin-top: 2rem;
    width: 95%;
    border: 1px solid #27272a;
  }
  
  .nftboxheading2 > p {
    width: 100%;
  }
  .imgboxcardss {
    width: 100%;
    min-height: 50vh;
    /* background-color: rgb(199, 125, 125); */
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  /*second section  */
  .imgboxcard {
    width: 100%;
min-height: 50vh;
  /* background-color: skyblue; */
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  }
  
  .imgboxcard1,
  .imgboxcard2
   {
    width: 100%;
    height: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    gap: 100px;
    padding: 2rem 0;
    margin-bottom: 2rem;

  }
  .imgboxcard1 > .cards,
  .imgboxcard2 > .cards
   {
    width: 43%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  }
  .imgboxcard1 > .cards > .imuu ,
  .imgboxcard2 > .cards > .imuu 
  {
    height: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
  }
  
  .box {
    height: 30%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: #27272a;
  
    @apply rounded-xl; */
  }
  
  .cards > .imuu > .curency,
  .cards > .imuu > .receipt,
  .cards > .imuu > .mask1 {
    height: 185px;
    width: 185px;
  }
  .cards > .imuu > .centralize{
  width: 240px;
  height: 200px;
  }
  .cards > .imuu > .baht {
    width: 185px;
    height: 185px;
  }
  .cards > .imuu > .mask2,
  .cards > .imuu > .padlock
   {
    width: 185px;
    height: 185px;
  }
  .accordion {
    cursor: pointer;
    height: 25%;
    padding: 0.5rem 0rem;
    /* margin-top: 1rem; */
    transition: 0.6s ease-in;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .title {
    /* min-width: 200px; */
    color: #fff;
    justify-content: space-between;
    display: flex;
    align-items: center;
    transition: 0.6s ease-in;
    padding: 1rem ;
    background: #27272a;
    @apply rounded-xl;
  
  }
  .cards:hover {
    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
    /* background-color: #505B6B; */
    padding: 0.1rem 0rem;
  }
  
  .indicator {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .name {
    /* width: 350px; */
    display: flex;
    justify-content: first baseline;
    align-items: center;
  
    /* @apply flex items-center; */
  }
  .name > h2 {
    font-size: 1.2rem;
    font-weight: 600;
    letter-spacing: 1px;
  }
  
  .reveal {
    width: 100%;
    min-height: 100px;
    display: block;
    /* margin-top: 0.5rem; */
    padding: 0.3rem 0rem;
    z-index: 999;
    /* color: #fff; */
    font-size: 1.1rem;
    font-weight: 300;
    /* line-height: 1.4rem; */
    transition: 0.5s ease;
    letter-spacing: 0.1em;
    white-space: pre-line;
    /* background-color: red; */
  }
  .reveal>p{
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 1.4rem;
    transition: 0.5s ease;
    letter-spacing: 0.1em;
    white-space: pre-line;
    color: #fff;
  }
  .reveal>p>span{
    font-weight: 700;
    color:yellow;
  }
  .indicator {
    padding: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #09090b;
    @apply rounded-full;
  }
  .indicator > svg {
    width: 1rem;
    height: auto;
    fill: #fff;
    /* padding : 1rem; */
  }
  .section3 {
    width: 90%;
    min-height: 350px;
    background-color: #09090b;
    border-radius: 50px;
    overflow: hidden;
    padding-bottom: 1rem;
    display: flex;
    padding: 0 2rem;
    padding-bottom: 2rem;
    box-shadow: 0px 0px 10px 0px #00000066;
    flex-direction: column;
  }
  .section31 {
    width: 90%;
    min-height: 20vh;
    background-color: #09090b;
    border-radius: 50px;
    overflow: hidden;
    display: flex;
    padding: 0 2rem;
    box-shadow: 0px 0px 10px 0px #00000066;
    flex-direction: column;
   
  }
  
  .nftboxheading3 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.7rem 0rem;
    flex-direction: column;
  }
  .nfth1 {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: flex-start;
    padding: 1rem 1rem;
    justify-content: flex-start;
  }
  
  .nfttextbox{
  width: 100%;
  min-height: 200px;
  padding: 0rem 1rem;
  @apply flex justify-start pb-4 ;
  }
  .section31>.nftboxheading3>.nfttextbox{
    min-height: 20px !important;
  }
  .nfttextbox>.div2>p{
    width: 80%;
  }
  .div1{
  width: 60%;
  }
  .div2{
    width: 40%;
  
      }
  .btn {
    padding: 0.8rem 1.7rem;
    height: 43px;
    width: 186px;
    box-shadow: 0px 4px 15px 0px #2e2e2e26;
    background: #F3CC2FCC;
    border-radius: 30px;
    color: aliceblue;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 1rem;
    margin-bottom: 1rem;
  filter: drop-shadow(7px 10px 7px  #00000066);
     @apply flex items-center justify-center;
  }
  

/* section3 */
.section21 {
  width: 90%;
  min-height: 80vh;
  border-radius: 30px;
  box-shadow: 0px 0px 10px 0px #00000080;
  background: #09090b;
  @apply flex flex-col pb-12 overflow-hidden;
}
.nftboxheading3 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7rem 1rem;
  padding-bottom: 3rem;
  flex-direction: column;
}
.nfth11 {
  width: inherit;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 3rem 0;
  justify-content: center;
}
.accordion21 {
  cursor: pointer;
  height: 25%;
  padding: 1rem 1.3rem;
  /* margin-top: 1rem; */
  transition: 0.6s ease-in;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.accordionsection2 {
  cursor: pointer;
  transition: 0.6s ease-in;
  width: 90%;
  height: auto;
  /* background-color: #aa4444; */

  @apply flex flex-col gap-3 pt-3;
}
.accordion2 {
  cursor: pointer;
  transition: 0.6s ease-in;
  width: 100%;
  background-color: #27272a;
  @apply rounded-3xl;
}
.name2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.title2 {
  color: #fff;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0.7rem 0.3rem;
}
.title2 > h2 {
  text-align: left;
}
.reveal1 {
  width: 100%;
  min-height: 100px;
  display: flex;
  flex-flow: column;
  padding: 0rem 0.3rem;
  z-index: 999;
  /* line-height: 1.4rem; */
  transition: 0.6s ease-in;
/* background-color: #fff; */
gap: 20px;
  /* letter-spacing: 0.1em; */
}
.reveal1 > hr{
  width: 100%;
  margin-bottom: 0.3rem;
  border: 1px solid #0E7490B2;
}
.reveal1>p{
  font-weight: 400;
  transition: 0.5s ease-in;
}
.section212{
  width: 100%;
  min-height:100vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* background-color: #9a7d7d; */
  flex-direction: column;
/* gap: 10px; */
}
.sec2part,.sec2part2{
  width: 100%;
  min-height:50vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  /* background-color: #a14e4e; */
  padding: 0rem 2rem;
  padding-bottom: 2rem;
  flex-direction: column;
}
.sec2part2{
  /* background-color: yellow; */
}
.doorcardss{
  width: 100%;
  min-height:50vh ;
  /* background-color: tan; */
  @apply flex justify-center flex-col items-center;
}
.door11cards{
  width: 100%;
  height: 340px;
  gap:20px 40px;
  /* margin-top: 1.5rem; */
  /* background-color: yellow; */
  padding: 1rem 1rem;
  @apply flex justify-start items-start;
}
.door11card1{
  width: 45%;
  height: 100%;
/* gap:40px; */
border-radius: 20px;
padding: 2rem 2.5rem;
background-color: #09090b;
box-shadow: 20px 20px 4px 0px #00000040;
@apply flex items-center justify-center flex-col;
}
.door11card1>h1{
  text-align: center;
}
.door11card1>p{
font-weight: 400;
line-height: 2.3rem;
letter-spacing: 0px;
text-align: center;

}











  @media screen and (max-width: 280px) {
    .section1,
    .section2,.section3,.section11,.section1aa,.section21  {
      width: 95%;
      border-radius: 10px;
      padding: 0.4rem;
      @apply flex-col gap-10;
    }
   .walletboxheading19>h1{
    font-size: 1.8rem;
   }
   .walletboxheading19>p{
    font-size: 1rem;
   }
    .walletboxheading,.walletboxheading19 {
      width: 100%;
      /* padding: 1rem 0; */
      @apply pl-4;
    }
    .walletimgbox,.walletimgbox1 {
      width: 100%;
    }
    .walletimgbox > img ,.walletimgbox1>img{
      width: 100%;
      height: 100%;
    }
    .imgboxcard {
      min-height: 50vh;
    }
    .imgboxcard1,.imgboxcard2 {
      margin-bottom: 0;
      @apply flex-col;
    
    }
    .imgboxcard1 > .cards,
    .imgboxcard2>.cards
     {
      width: 100%;
      height: 40%;
    }
  
    .box {
      width: 100%;
      height: 40%;
      /* justify-content: flex-start; */
    }
    .nftboxheading2 > p {
      width: 100%;
      font-size: 0.9rem;
      text-align: left;
      line-height: 1.5rem;
    }
    .walletboxheading{
      padding-left:0.4rem ;
    }
    .walletboxheading>h1{
      font-size: 2rem;
    }
    .walletboxheading>p{
      font-size: 1rem;
    }
    .name > h2 {
      font-size: 1.1rem;
    }
    .nftboxheading2 {
      padding: 0.2rem 0.5rem;
    }
      .nfttextbox{
        padding: 0 0.9rem;
        @apply flex-col;
      }
      .nftboxheading3{
        padding: 0.7rem 0rem;
      }
      .nfth1>h1{
        font-size: 1.6rem;
        line-height: 1.9rem;
        text-align: left;
      }
      .div1,.div2{
        width: 100%;
          }
          .div1>p{
            font-size: 1rem;
          }
          .nfttextbox>.div2>p{
            width: 100%;
            font-size: 1rem;
          }
    .nftboxheading2 > h1 {
        width: 100%;
        font-size: 0.9rem;
        line-height: 1.5rem;
      }
      .sec2part2,.door11cards{
        flex-direction: column;
      }
      .sec2part2{
        min-height: 135vh;
      }
      .sec2part{
        min-height: 145vh;
padding-bottom: 0;
      }
      .door11cards{
        padding: 0;
      }
      .door11card1{
        width: 100%;
        padding: 2rem 1.5rem;
      }
      .nfth11{
        padding: 3rem 0;
      }
       .nfth11>h1{
        font-size: 1.2rem;
        line-height: 1.5rem;
        /* padding: 6rem 0; */
      } 
      .accordionsection2{
        width: 100%;
      }
      .accordion21{
        padding: 1rem 0.3rem;
      }
      .name2>h2{
        font-size: 1rem;
        line-height: 1.3rem;
      }
      .accordion2{
        border-radius: 0.5rem;
      }
      .reveal1>p{
        font-size: 1rem;
      }
      .indicator>svg{
    width: 1rem;
      }
      
      .door11card1>h1{
        font-size: 1.2rem;
      }
      .door11card1>p{
        line-height: 1.5rem;
        font-size: 0.9rem;
      }
     
       
      
     
  }

  @media (min-width: 289px) and (max-width: 765px) {
    .section1,
    .section2,.section3,.section11,.section1aa ,.section21{
      /* width: ; */
      border-radius: 20px;
      padding: 0.5rem;
      @apply flex-col gap-10;
    }
    .nfth11,.nfth1{
      padding: 2rem 0;
    }
    .nfth11>h1,.nfth1>h1{
      font-size: 1.4rem;
      line-height: 1.9rem;
    }
    .accordionsection2{
      width: 100%;
    }
    .accordion21{
      padding: 1rem 0.3rem;
    }
    .name2>h2{
      font-size: 1rem;
      line-height: 1.3rem;
    }
    .accordion2{
      border-radius: 0.5rem;
    }
    .reveal1>p,.reveal>p{
      font-size: 1rem;
    }
    .indicator>svg{
  width: 1rem;
    }
   
    
    .door11card1>h1{
      font-size: 1.2rem;
    }
    .door11card1>p{
      line-height: 1.5rem;
      font-size: 0.9rem;
    }
    
    .sec2part>.nfth11{
      padding: 2rem 0;
    }
    .sec2part>.nfth11>h1{
      font-size: 1.4rem;
    }
    .sec2part2,.door11cards{
      flex-direction: column;
    }
      .sec2part2{
        min-height: 95vh;
        /* justify-content: center; */
  
      }
      .sec2part{
        min-height: 90vh;
        /* justify-content: center; */
  
      }
    .door11cards{
      /* padding: 0; */
    }
    .door11card1{
      width: 100%;
      /* padding: 5rem 2.5rem; */
    }

    .walletboxheading ,.walletboxheading19{
      width: 100%;
      @apply pl-4;
    }
    .walletimgbox ,.walletimgbox1{
      width: 100%;
      @apply flex items-center justify-center;
    }
    .imgboxcard {
      min-height: 50vh;
    }
    .imgboxcard1,.imgboxcard2 {
      margin-bottom: 0;
      @apply flex-col;
    
    }
    .imgboxcard1 > .cards,
    .imgboxcard2 > .cards
     {
      width: 100%;
      height: 40%;
    }
  
    .box {
      width: 100%;
      height: 40%;
      /* justify-content: flex-start; */
    }
    .nftboxheading2 > p {
      width: 100%;
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: left;
  
    }
  
    .name > h2 {
      font-size: 1.3rem;
    }
    .nftboxheading2 {
      padding: 0.2rem 0.5rem;
    }
    .nfttextbox{
        padding: 0 0.9rem;
        @apply flex-col;
      }
      .nfth1>h1{
        font-size: 1.6rem;
        line-height: 1.9rem;
        text-align: left;
      }
      .div1,.div2{
        width: 100%;
          }
          .div1>p{
            font-size: 1rem;
          }
          .nfttextbox>.div2>p{
            width: 100%;
            font-size: 1rem;
  
          }
          .nftboxheading2 {
            padding: 0.2rem 0.5rem;
          }
  }
  
  @media (min-width: 766px) and (max-width: 912px) {
    .walletboxheading > p,.walletboxheading19>p {
      width: 90%;
    }
   
    .walletboxheading,.walletboxheading19 {
      width: 100%;
      padding: 1rem 0;
      @apply pl-4;
    }
    .section1,
    .section2,.section3,.section11,.section1aa,.section21 {
      width: 95%;
      border-radius: 20px;
      @apply flex-col gap-10; 
    }
    .sec2part,.sec2part2{
      padding: 0 0;
      min-height: 40vh;
      padding-bottom: 0;
    }
    .accordionsection2{
      width: 100%;
    }
    .accordion21{
      padding: 1rem 0.3rem;
    }
    .name2>h2{
      font-size: 1rem;
      line-height: 1.3rem;
    }
    .accordion2{
      border-radius: 0.5rem;
    }
    .reveal1>p,.reveal>p{
      font-size: 1rem;
    }
    .indicator>svg{
  width: 1rem;
    }
    .door11card1{
      gap: 0 10px;
      /* height: 90%; */
      border-radius: 5px;
      padding: 1rem 0.8rem;
    }
    .door11card1>h1{
      font-size: 1.2rem;
    }
    .door11card1>p{
      line-height: 1.5rem;
      font-size: 0.9rem;
    }
    .doorcardss{
      min-height: 30vh;
    }
    .door11cards{
      gap: 25px;
      padding: 0 1rem;
      height: 300px;
    }
    
    .walletimgbox>img,.walletimgbox1>img {
      height: 488px;
      width: 416px;
    }
    .section2{
      padding: 0 0.3rem;
      padding-bottom: 3rem;
    }
    .walletimgbox,.walletimgbox1 {
      width: 100%;
      @apply flex items-center justify-center;
    }
    .imgboxcard1 > .cards > .imuu {
      width: 100%;
    }
    .imgboxcard {
      min-height: 30vh;
    }
    .imgboxcardss {
      min-height: 30vh;
      /* margin-bottom: 4rem; */
    }
   
    .imgboxcard1 ,.imgboxcard2{
      /* width: 40%; */
      margin-bottom: 0;
      gap: 50px;
    }
    .section21{
      min-height: 50vh;
    }
    .nftboxheading2{
      padding: 1rem;
    }
    .nftboxheading2>p{
      width: 100%;
  
    }
    .imgboxcard1>.cards>.imuu,
    .imgboxcard2>.cards>.imuu
    {
height: 33%;
    }
  }
  @media (min-width:540px) {
    .walletimgbox>img, .walletimgbox1>img{
        width: 300px;
        height: 300px;
    }
    .door11cards{
      height: 265px;
    }
    .doorcardss{
      min-height: 37vh;
    }
  }
  @media (min-width: 913px) and (max-width: 1024px) {
    .section1,.section2,.section3,.section11,.section1aa,.section21 {
      width: 95%;
      border-radius: 20px;
      padding-bottom: 1.2rem;
    }
    .section2{
      padding-bottom: 2rem;
    }
    .section21{
      min-height: 50vh;
    }
    .imgboxcard1 ,.imgboxcard2{
      /* width: 40%; */
      margin-bottom: 0;
      gap: 50px;
    }
    .walletboxheading ,.walletboxheading19{
      padding-left: 2rem;
      padding-right: 1rem;
    }
    .imgboxcard {
      min-height: 30vh;
    }
    .walletimgbox>img,.walletimgbox1>img{
        width: 300px;
        height: 300px;
    }
    .doorcardss{
      min-height: 25vh;
      /* justify-content: flex-start; */
    }
    .door11cards{
      padding: 0;
    }
    .door11card1>p{
      line-height: 2rem;
    }
    .sec2part,.sec2part2{
      min-height: 30vh;
    }
    .imgboxcardss {
      min-height: 30vh;
      margin-bottom: 4rem;
    }
  }
  