.box {
  background-image: url("../../assets/Images/_iphone-p-500.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  /* color: #f1d7c8; */
}


input[type=radio]{
	
	display: none;
}
.star{
	cursor: pointer;
}
.greetingcards::-webkit-scrollbar{
	display: none;
}
.greetingcards::-webkit-scrollbar-thumb{
  scroll-behavior: smooth;
}


/* CSS to position options above the select element */
.options-above {
  position: absolute;
  top: -100%; /* Adjust this value to position it correctly */
  left: 0;
  width: 100%;
  max-height: 200px; /* Set a max height for the dropdown if needed */
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0 0 12px 12px; /* Adjust border radius as needed */
  z-index: 1; /* Ensure the options are above other elements */
}

.option {
  padding: 8px;
  cursor: pointer;
}

/* Style the selected option */
option:checked {
  background-color: #e2e8f0;
}









.animation,
.animation_layer {
  height: 1000px;
}
.animation {
  display: block;
  position: relative;
  z-index: 10;
}
.animation_layer {
  background-position: bottom center;
  background-size: auto 1038px;
  background-repeat: repeat-x;
  width: 100%;
  position: absolute;
}
.animation_layer.parallax {
  position: fixed;
}
#a1 {
  width: 100%;
  height: 100px;
  background-color: blue;
}
#a2 {
  width: 100%;
  height: 200px;
  background-color: rgb(0, 255, 191);
}
#a3 {
  width: 100%;
  height: 300px;
  background-color: rgb(157, 255, 0);
}
#a4 {
  width: 100%;
  height: 400px;
  background-color: rgb(255, 157, 0);
}
#a5 {
  width: 100%;
  height: 50px; 
  background-color: rgb(255, 0, 157);
}




/* MouseCircle.css */

.mouse-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.circle {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: red; /* Set the color of the circle */
  /* transform: translate(-50%, -50%); */
  top: 0;
left: 0;
}


.mouse-tracker {
  position: relative;
  width: 100%;
  height: 100vh;
 
}


.circle {
  position: absolute;
  border-radius: 50%;
  background-color: #3498db;
  transition: all 0.3s ease-in-out;
}

.tracked-div {
  position: absolute;
  width: 200px;
  height: 200px;
  background-color: #f1c40f;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}







 

.app {
  display: flex;
  align-items: center;
  justify-content: center;
   width: 100%;
  height: 100vh;
}

.app .particles {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
}

.app .particles .particle {
  position: absolute;
  transition: all 5s ease-out;
}

.app .particles .particle.circular {
  background-color: var(--color);
  width: var(--size);
  height: var(--size);
  border-radius: var(--size);
  transform: translate(var(--x), var(--y));
}

.app .particles .particle.rectangular {
  background-color: var(--color);
  width: var(--size);
  height: var(--size);
  transform: translate(var(--x), var(--y)) rotateX(var(--rotate)) rotateY(var(--rotate));
}

.app .particles .particle.squiggle {
  stroke: var(--color);
  stroke-width: 15px;
  stroke-linecap: round;
  overflow: visible;
  width: var(--size);
  height: var(--size);
  transform: translate(var(--x), var(--y)) rotateY(var(--rotate));
}

.app .button {
  height: 40px;
  width: 200px;
  font-family: Roboto;
  background-color: #34495e;
  color: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .2s ease-out;
  user-select: none;
}

.app .button:hover {
  background-color: #8e44ad;
}

.app .button .popper {
  margin-right: 20px;
  margin-left: -20px;
  width: 64px;
  height: 64px;
  background-image: url('https://cdn.yoavik.com/codepen/react-confetti/confetti.png');
}

