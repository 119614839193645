body {
  @apply min-h-full w-full;
}
.container9 {
  width: 100%;
  height: auto;
  overflow: hidden;
  gap: 40px;
  background-color: #88c0b7;
  padding-bottom: 2rem;
  @apply relative flex flex-col items-center justify-center;
}
.section1 {
  width: 90%;
  background: #0f766e;
  min-height: 385px;
  margin-top: 2rem;
  box-shadow: 0px 0px 10px 0px #00000080;
  border-radius: 50px;
  z-index: 1;

  position: relative;
  @apply flex justify-between  overflow-hidden;
}
.walletboxheading {
  width: 65%;
  min-height: 100%;
  display: flex;
  /* background-color: red; */
  @apply flex-col justify-start items-start pl-6 pt-7 pb-10;
}
.walletboxheading > h1 {
  padding: 0.7rem 1rem;
  background-color: #88c0b7;
  text-align: left;
  @apply rounded-2xl;
}
.walletimg {
  width: 35%;
  min-height: 385px;
  @apply flex justify-center items-center;
}
.walletimg > img {
  width: 350px;
  height: 350px;
  filter: drop-shadow(7px 7px 10px #00000066);
}
.section2 {
  background-color: #0f766e;
  width: 90%;
  min-height: 100vh;
  border-radius: 50px;
  box-shadow: 0px 0px 10px 0px #00000080;
  @apply pb-12 overflow-hidden;
}
.nftboxheading2 {
  width: 100%;
  /* padding: 3rem 2rem; */
  min-height: 100px;
  @apply flex justify-center items-center pl-12 pt-2;
}
.nftboxheading21 {
  width: 100%;
  padding: 3rem 0;
  @apply flex justify-start items-start pl-14 mt-10;
}
.nftboxheading21 > p {
  width: 90%;
}

.nftboxheading2 > h1 {
  text-align: left;
}

/*second section  */
.imgboxcard {
  width: 100%;
min-height: 50vh;
  /* background-color: skyblue; */
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.imgboxcard1 {
  width: 100%;
height: 100%;
display: flex;
/* align-items: center; */
justify-content: center;
gap: 100px;
padding: 2rem 0;
}
.imgboxcard1 > .cards {
  width: 40%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.imgboxcard1 > .cards > .imuu {
  height: 65%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 2.5rem 0;
  /* background-color: red; */
}
.cards>.imuu>img{
  filter: drop-shadow(7px 7px 10px #00000066);
}
.box {
  height: 30%;
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.cards > .imuu > .padlock {
  width: 185px;
  height: 185px;
}
.accordion {
  cursor: pointer;
  height: 25%;
  /* padding: 1rem 1rem; */
  /* margin-top: 1rem; */
  transition: 0.6s ease-in;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  min-width: 200px;
  color: #fff;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 1rem 1rem;
  /* margin-top: 1rem; */
  transition: 0.6s ease-in;
  background: linear-gradient(
    0deg,
    rgba(94, 234, 212, 0.5),
    rgba(94, 234, 212, 0.5)
  ); 
  @apply rounded-xl;

}
.cards>.box:hover {
  transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.5s;
  /* background-color: #505B6B; */
  padding: 0.1rem 0rem;
}

.indicator {
  display: flex;
  justify-content: center;
  align-items: center;
}
.name {
  /* width: 350px; */
  display: flex;
  justify-content: first baseline;
  align-items: center;

  /* @apply flex items-center; */
}
.name > h2 {
  font-size: 1.2rem;
  font-weight: 600;
  letter-spacing: 1px;
}

.reveal {
  width: 100%;
  /* min-height: 100px; */
  display: block;
  /* margin-top: 0.5rem; */
  padding: 0.3rem 0rem;
  z-index: 999;
  color: #fefffc;
  font-size: 1.1rem;
  font-weight: light;
  line-height: 1.4rem;
  transition: 0.5s ease;
  background-color: transparent;
  letter-spacing: 0.1em;
  /* background-color: red; */
}
.indicator {
  padding: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #3f6212; */
  background-color: #0f766e;
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), */
  @apply rounded-full;
}
.indicator > svg {
  width: 1rem;
  height: auto;
  fill: #fff;
  /* padding : 1rem; */
}
@media screen and (max-width: 280px) {
    .section1,
    .section2 {
      border-radius: 10px;
      padding: 0.4rem;
      @apply flex-col gap-10;
    }
    .nftboxheading2 {
        padding: 0.5rem 0rem;
      }
      .nfttextbox{
        padding: 0 0.9rem;
        @apply flex-col;
      }
    .nftboxheading2 > h1 {
        width: 100%;
        font-size: 0.94rem;
        line-height: 1.5rem;
      }
    .walletboxheading {
      width: 100%;
      @apply pl-4;
    }
    .walletimg{
        width: 100%;
    } 
    .walletimg>img{
        width: 250px;
        height: 250px;
    }
    .imgboxcard {
      min-height: 100vh;
    }
    .imgboxcard1 { 
      @apply flex-col;
    }
    .imgboxcard1 > .cards {
      width: 100%;
      height: 40%;
    }
  
    .box {
      width: 100%;
      height: 40%;
      /* justify-content: flex-start; */
    }
    .nftboxheading21 > p {
      width: 100%;
      font-size: 0.9rem;
      text-align: left;
      line-height: 1.5rem;
    }
    .nftboxheading21{
        padding-left: 0.5rem;
    }
    .name > h2 {
      font-size: 1.1rem;
    }
    .nftboxheading2 {
      padding: 0.2rem 0.5rem;
    }
  }
  @media (min-width: 289px) and (max-width: 765px) {
    .section1,
    .section2 {
      width: 93%;
      border-radius: 20px;
      padding: 0.5rem;
      margin-left: 0;
      @apply flex-col gap-10;
    }
     .walletboxheading {
      width: 100%;
      @apply pl-4 ;
    }
    .walletboxheading>h1{
        padding-bottom: 1rem;
        font-size: 1.8rem;
    }
    .walletimg{
        width: 100%;

    }
    .imgboxcard {
      min-height: 40vh;
    }
    .imgboxcard1 {
     
      @apply flex-col;
    }
    .imgboxcard1 > .cards {
      width: 100%;
      height: 40%;
    }
    .nftboxheading2 {
        padding: 0.5rem 0.5rem;
      }
    
      .nftboxheading2 > h1 {
        width: 100%;
        font-size: 1.25rem;
        line-height: 1.7rem;
      }
  
    .box {
      width: 100%;
      height: 40%;
      /* justify-content: flex-start; */
    }
    .nftboxheading21{
        padding-left: 0.5rem;
    }
    .nftboxheading21 > p {
      width: 100%;
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: left;
      letter-spacing: 0.1em;
    }
    .name > h2 {
      font-size: 1.3rem;
    }
    .nftboxheading2 {
      padding: 0.2rem 0.5rem;
    }
    
  }
  @media (min-width:540px) {
    .walletimg>img{
        width: 300px;
        height: 300px;
    }
  }
  @media (min-width: 766px) and (max-width: 912px) {
    .walletboxheading > p{
      width: 90%;
    }
    
    .imgboxcard1 {
        @apply flex-row;
      }
    .walletboxheading {
      width: 100%;
      padding: 1rem 0;
      @apply pl-4;
    }
    .section1,
    .section2 {
      width: 90%;
      border-radius: 20px;
      @apply flex-col gap-10; 
    }
   
    .walletimg{
      width: 100%;
      @apply flex items-center justify-center;
    }
    .walletimg>img{
        width: 300px;
        height: 300px;
    }
    .box{
        width: 100%;
    }
    .imgboxcard1 > .cards > .imuu {
      width: 100%;
    }
    .imgboxcard {
      min-height: 40vh;
    }
    .nftboxheading2{
        padding-left: 0rem;

    }
    .nftboxheading21{
        /* padding-left: 2.5rem; */
        padding: 2rem 1.5rem;
        margin-top: 2rem;
    }
    .reveal{
      font-size: 0.9rem;
      padding: 0;
      letter-spacing: 0;
    }
    .nftboxheading2>h1{
        text-align: center;
    }
  }
  @media (min-width: 913px) and (max-width: 1024px) {
    .section1,.section2 {
      width: 95%;
      border-radius: 20px;
      padding-bottom: 1.2rem;
    }
    .nftboxheading2>h1{
        text-align: center;
    }
    
    .walletboxheading {
      padding-left: 2rem;
      padding-right: 1rem;
    }
    .imgboxcard {
      min-height: 30vh;
    }
    .nftboxheading2{
        width: 99%;
    }
    .nftboxheading21{
        padding-left: 2.5rem;
    }
    .reveal{
      font-size: 1rem;
    }
   
  }