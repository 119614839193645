/* @import url('https://fonts.googleapis.com/css2?family=Raleway'); */
/* @import url('https://fonts.googleapis.com/css2?family=Comfortaa'); */
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Fredoka:wght@300..700&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.containerIcon {
  display : flex;
  align-items : center;
  justify-content : center;  
  /* font-family: 'Raleway', sans-serif; */
  font-family: 'Nunito', sans-serif;
  
  /* background-color: #43345d; */
  /* min-height : 100vh; */
  /* min-width: 100vw; */
}

.containerIcon {
  position : relative;
  /* width : 1100px; */
  display : flex;
  align-items : center;
  justify-content : center;
  flex-wrap : wrap;
  padding : 30px;  
}

.containerIcon .card {
  position: relative;
  max-width : 300px;
  height : 215px;  
  background-color : #f8f5f5;
  margin : 30px 10px;
  padding : 20px 15px;
  
  display : flex;
  flex-direction : column;
  box-shadow : 0 5px 20px rgba(0,0,0,0.5);
  transition : 0.3s ease-in-out;
  border-radius : 15px;
}
.containerIcon .card:hover {
  height : 320px;    
}


.containerIcon .card .image {
  position : relative;
  width : 260px;
  height : 260px;
  background-color: aliceblue;
  top : -40%;
  left: 8px;
  box-shadow : 0 5px 20px rgba(0,0,0,0.2);
  z-index : 1;
}

.containerIcon .card .image img {
  max-width : 100%;
  border-radius : 15px;
}

.containerIcon .card .content {
  position : relative;
  top : -140px;
  padding : 10px 15px;
  color : #111;
  text-align : center;
  visibility : hidden;
  opacity : 0;
  transition : 0.3s ease-in-out;
    
}

.containerIcon .card:hover .content {
   margin-top : 30px;
   visibility : visible;
   opacity : 1;
   transition-delay: 0.2s;
  
}




.backgroundimg {
  background-image: linear-gradient(
    45deg,
    hsl(55deg 75% 87%) 0%,
    hsl(54deg 76% 90%) 6%,
    hsl(54deg 78% 93%) 13%,
    hsl(53deg 79% 96%) 20%,
    hsl(53deg 80% 99%) 28%,
    hsl(300deg 100% 100%) 36%,
    hsl(300deg 100% 100%) 45%,
    hsl(300deg 100% 100%) 55%,
    hsl(300deg 100% 100%) 64%,
    hsl(0deg 0% 100%) 72%,
    hsl(52deg 84% 99%) 78%,
    hsl(52deg 83% 97%) 83%,
    hsl(53deg 82% 96%) 87%,
    hsl(53deg 82% 94%) 90%,
    hsl(53deg 81% 93%) 93%,
    hsl(53deg 80% 92%) 95%,
    hsl(53deg 79% 91%) 97%,
    hsl(53deg 79% 90%) 99%,
    hsl(53deg 79% 89%) 100%
  );
}





/* for scrool animation  */


/* 
.hiden{

  opacity: 0;
  filter: blur(5px);
  transform: translateX(-100%);
  transition: all 3s;
 
}

.show
{
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}


.logo:nth-child(2){
transition-delay: 300ms;
}
.logo:nth-child(3){
transition-delay: 600ms;
}
 */




