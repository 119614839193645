/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  } */
  body {
    @apply min-h-full w-full;
  }
  .container11 {
    width: 100%;
    height: auto;
    overflow: hidden;
    gap: 40px;
    background: #fca5a5;
    padding-bottom: 2rem;
    @apply relative flex flex-col items-center justify-center;
  }
  .section1 {
    min-height: 270px;
    width: 90%;
    background-color: #fca5a5;
    margin-top: 1rem;
    gap: 16px;
    @apply flex justify-center items-center;
  }
  .sec1box{
width: 100%;
height:100%;
@apply flex justify-between items-center;
  }
.sec1stablecoinleft{
width: 60%;
height: 100%;
padding: 2rem 2rem;

}
.sec1stablecoinleft>h1{
  text-align: center;
}
.sec1stablecoinright{
  width: 40%;
  height: 100%;
  @apply flex justify-between flex-col items-center;
}
.webimgbox{
width: 420px;
height: 220px;
/* background-color: red; */
gap:5px 5px;
@apply flex items-center justify-between;
}
.card1{
  width: 200px;
  height: 200px;
  overflow: hidden;
  /* position: relative; */
}
.card1>img{
  width: 100%;
  height: 100%;
  object-fit:cover;
}

  .section2 {
    width: 90%;
    min-height: 80vh;
    border-radius: 30px;
    box-shadow: 0px 0px 10px 0px #00000080;
    background: #f26969;
    @apply flex flex-col pb-12 overflow-hidden;
  }
  .section21 {
    width: 90%;
    min-height: 80vh;
    border-radius: 30px;
    box-shadow: 0px 0px 10px 0px #00000080;
    background: #f26969;
    @apply flex flex-col pb-12 overflow-hidden;
  }
  .nftboxheading3 {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.7rem 1rem;
    padding-bottom: 3rem;
    flex-direction: column;
  }
  .sec2part{
    width: 100%;
    min-height:384px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.7rem 1rem;
    padding-bottom: 3rem;
    flex-direction: column;
  }
  .nfth1 {
    width: inherit;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 3rem 0;
    justify-content: center;
  }
  .accordion {
    cursor: pointer;
    height: 25%;
    padding: 1rem 1.3rem;
    /* margin-top: 1rem; */
    transition: 0.6s ease-in;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .accordionsection2 {
    cursor: pointer;
    transition: 0.6s ease-in;
    width: 90%;
    height: auto;
    /* background-color: #aa4444; */
  
    @apply flex flex-col gap-3 pt-3;
  }
  .accordion2 {
    cursor: pointer;
    transition: 0.6s ease-in;
    width: 100%;
    background-color: #fca5a5;
    @apply rounded-3xl;
  }
  .name2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .title2 {
    color: #000;
    justify-content: space-between;
    display: flex;
    align-items: center;
    padding: 0.7rem 0.3rem;
  }
  .title2 > h2 {
    text-align: left;
  }
  .indicator {
    padding: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f26969;
    box-shadow: 0px 6px 10px 0px #0E7490B2;
    @apply rounded-full;
  }
  .indicator > svg {
    width: 2rem;
    height: auto;
    fill: #000;
    /* padding : 1rem; */
  }
  .reveal {
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-flow: column;
    padding: 0rem 0.3rem;
    z-index: 999;
    /* line-height: 1.4rem; */
    transition: 0.6s ease-in;
/* background-color: #fff; */
gap: 20px;
    /* letter-spacing: 0.1em; */
  }
  .reveal > hr{
    width: 100%;
    margin-bottom: 1rem;
    border: 1px solid #0E7490B2;
  }
  .reveal>p{
    font-weight: 400;
    transition: 0.5s ease-in;
  }
  .reveal>img{
    width: 145px;
    height: 155px;
    /* background-color: #f26969; */
  }
  .doorcardss{
    width: 100%;
    min-height:100vh ;
    @apply flex justify-center flex-col items-center;
  }
  .door11cards,.door11cards2{
    width: 95%;
    height: 340px;
    gap:20px 40px;
    margin-top: 1.5rem;
    @apply flex justify-between items-center;
  }
  .door11card1{
    width: 47%;
    height: 100%;
gap:40px;
padding: 2rem 2.5rem;
background: linear-gradient(0deg, #FCA5A5, #FCA5A5);
box-shadow: 20px 20px 4px 0px #00000040;
@apply flex items-center justify-center flex-col;
  }
  .door11card1>h1{
    text-align: center;
  }
  .door11card1>p{
font-weight: 400;
line-height: 2.5rem;
letter-spacing: 0px;
text-align: center;

  }


  
  .section3 {
    width: 90%;
    border-radius: 30px;
    background-color: #f26969;
    min-height: 300px;
    padding: 1.5rem 3rem;
    gap:10px;
    @apply flex items-start flex-col justify-start overflow-hidden;
  }
  .doo11sec3box{
    display: flex;
    width: 100%;
    min-height: 300px;
gap: 4rem;
    @apply flex justify-between items-start ;
  }
  .doo11sec3box>div{
width: 100%;
height: 100%;
gap: 16px;
    padding: 1rem 0;
    @apply flex flex-col;

  }
  .btn{
    width: 186px;
height: 43px;
padding: 18px 32px 18px 32px;
border-radius: 30px;
text-decoration: underline;
background: #F3CC2FCC;
font-weight: 600;
box-shadow: 0px 4px 15px 0px #2E2E2E26;
@apply flex items-center justify-center;
  }


@media screen and (max-width:280px) {
  .sec1box{
    flex-direction: column;

  }
  .sec1stablecoinleft{
    width: 100%;
    padding: 1rem 0.3rem;
  }
  .sec1stablecoinleft>p{
    text-align: left;
  }
  .sec1stablecoinleft>h1{
    font-size: 2.5rem;
    text-align: left;
  }
  .webimgbox{
width: 250px;
height: 150px;
gap: 10px 9px;
  }
  .card1{
    width: 150px;
    height: 137px;
  }
  .sec1stablecoinright{
    width: 100%;
  }
  .section2,.section21{
    border-radius: 10px;
  }
  
  .nfth1{
    padding: 2rem 0;
  }
  .nfth1>h1{
    font-size: 1rem;
    line-height: 1.5rem;
    /* padding: 6rem 0; */
  }
  .accordionsection2{
    width: 100%;
  }
  .accordion{
    padding: 1rem 0.3rem;
  }
  .name2>h2{
    font-size: 1rem;
    line-height: 1.3rem;
  }
  .accordion2{
    border-radius: 0.5rem;
  }
  .reveal>p{
    font-size: 1rem;
  }
  .indicator>svg{
width: 1rem;
  }
  .door11card1{
    width: 220px;
height: 100%;
    gap: 0 10px;
    padding: 1rem 0.3rem;
  }
  .door11card1>h1{
    font-size: 1.2rem;
  }
  .door11card1>p{
    line-height: 1.5rem;
    font-size: 0.9rem;
  }
  .doorcardss{
    flex-direction: column;
min-height: 100vh;
  }
  .door11cards{
    width: 100%;
    flex-direction: column;
  }
  .door11cards2{
    flex-direction: column;
    margin-top: 8.5rem;
    min-height: 500px;
    height: 500px;
  }
  
  .sec2part>.nfth1{
    padding: 0rem 0;
  }
  .sec2part>.nfth1>h1{
    font-size: 1.2rem;
  }
  .section3{
    padding :1rem ;
    border-radius: 10px;
  }
  .section3>h1{
    font-size: 1.3rem;
  }
  .doo11sec3box>div>p{
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .doo11sec3box{
    gap: 0;
    flex-direction: column;
  }
 
}  
@media (min-width:281px) and (max-width:765px) {
  .sec1box{
    flex-direction: column;

  }
  .sec1stablecoinleft{
    width: 100%;
    padding: 1rem 0.3rem;
  }
  .sec1stablecoinleft>p{
    text-align: left;
  }
  .sec1stablecoinleft>h1{
    font-size: 2.5rem;
    text-align: left;
  }
  .webimgbox{
width: 320px;
height: 150px;
gap: 10px 9px;
  }
  .card1{
    width: 150px;
    height: 137px;
  }
  .sec1stablecoinright{
    width: 100%;
  }
  .section2,.section21{
    border-radius: 20px;
  }
  
  .nfth1{
    padding: 2rem 0;
  }
  .nfth1>h1{
    font-size: 1.4rem;
    line-height: 1.9rem;
  }
  .section2{
    min-height: 60vh;
  }
  .accordionsection2{
    width: 100%;
  }
  .accordion{
    padding: 1rem 0.3rem;
  }
  .name2>h2{
    font-size: 1rem;
    line-height: 1.3rem;
  }
  .accordion2{
    border-radius: 0.5rem;
  }
  .reveal>p{
    font-size: 1rem;
  }
  .indicator>svg{
width: 1rem;
  }
  .door11card1{
    width: 300px;
height: 100%;
    gap: 0 10px;
    padding: 1rem 0.3rem;
    box-shadow: 10px 10px 2px 0px #00000040;
  }
  
  .door11card1>h1{
    font-size: 1.2rem;
  }
  .door11card1>p{
    line-height: 1.5rem;
    font-size: 0.9rem;
  }
  .doorcardss{
    flex-direction: column;
min-height: 100vh;
  }
  .door11cards{
    width: 100%;
    flex-direction: column;
  }
  .door11cards2{
    flex-direction: column;
    margin-top: 4.4rem;
    height: 500px;
    height: 500px;
  }
   
  .sec2part>.nfth1{
    padding: 2rem 0;
  }
  .sec2part>.nfth1>h1{
    font-size: 1.4rem;
  }
  .section3{
    padding :1rem 1.5rem;
    border-radius: 20px;
  }
  .section3>h1{
    font-size: 1.3rem;
  }
  .doo11sec3box{
    gap: 0;
    flex-direction: column;
  }
  .doo11sec3box>div>p{
    font-size: 1rem;
    line-height: 1.5rem;
  }
 
}
@media (min-width:766px) and (max-width:912px) {
  
  .sec1stablecoinleft{
    padding: 1rem 0.3rem;
  }
  .sec1stablecoinleft>p{
    text-align: left;
  }
  .sec1stablecoinleft>h1{
    font-size: 2.5rem;
    text-align: left;
  }
  .webimgbox{
width: 275px;
height: 150px;
gap: 10px 9px;
  }
  .card1{
    width: 150px;
    height: 137px;
  }
  .section1{
    width: 93%;
  }
  .section2,.section21{
    width: 93%;
    border-radius: 20px;
  }
  
  .nfth1{
    padding: 2rem 0;
  }
  .nfth1>h1{
    font-size: 1.4rem;
    line-height: 1.9rem;
    /* padding: 6rem 0; */
  }
  .section2{
    min-height: 60vh;
  }
  .accordionsection2{
    width: 100%;
  }
  .accordion{
    padding: 1rem 0.3rem;
  }
  .name2>h2{
    font-size: 1rem;
    line-height: 1.3rem;
  }
  .accordion2{
    border-radius: 0.5rem;
  }
  .reveal>p{
    font-size: 1rem;
  }
  .indicator>svg{
width: 1rem;
  }
  .door11card1{
    gap: 0 10px;
    padding: 1rem 0.3rem;
  }
  .door11card1>h1{
    font-size: 1.2rem;
  }
  .door11card1>p{
    line-height: 1.5rem;
    font-size: 0.9rem;
  }
  .doorcardss{
    min-height: 60vh;
  }
  .section3{
    padding :1rem 1.5rem;
    border-radius: 20px;
  }
  .section3>h1{
    font-size: 1.5rem;
  }
  
  .doo11sec3box>div>p{
    font-size: 0.9rem;
    line-height: 1.5rem;
  }
 
}
   
@media (min-width:913px) and (max-width:1024px) {
  .doorcardss{
    min-height: 30vh;
    justify-content: flex-start;
  }
  .section21{
    min-height: 60vh;

  }
  .webimgbox{
    width: 320px;
    height: 170px;
  }
  .card1{
    width: 150px;
    height: 150px;
  }
  .section1{
    width: 93%;
  }
  .section2{
    min-height: 30vh;
  }
}